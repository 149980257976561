(function() {
    'use strict';
    angular.module('wbUtilities').directive('select', ['$state', '$timeout', function ($state, $timeout) {
        return {
            restrict: 'E',
            require: '?ngModel',
            link: function(scope, element, attrs, modelController) {
                var s2Obj;
                var KEYS = jQuery.fn.select2.amd.require("select2/keys");
                var attrOptions = attrs.s2Options ? scope.$eval(attrs.s2Options) : {};
                var placeholder = attrs.placeholder ? attrs.placeholder : "";
                var options = {};

                options = angular.extend({
                    theme: "bootstrap4",
                    minimumResultsForSearch: Infinity,
                    width: null,
                    placeholder: placeholder,
                    selectOnClose: !attrs.multiple
                }, attrOptions);

                createSelect();

                function createSelect() {
                    $timeout(function () {
                        element.select2(options);

                        s2Obj = $(element).data('select2');
                        scope.s2Obj = s2Obj;
                        extendSelect2();

                        element.select2Initialized = true;
                    });
                }

                var refreshSelect = function() {
                    if (!element.select2Initialized) return;
                    $timeout(function() {
                        element.trigger('change.select2');
                    });
                };

                var recreateSelect = function () {
                    if (!element.select2Initialized) return;
                    createSelect();
                };

                if (modelController) {
                    scope.$watch(function() { return modelController.$modelValue; }, function (model) {
                        refreshSelect();
                    });
                }

                if (attrs.ngOptions) {
                    var list = attrs.ngOptions.match(/ in ([^ ]*)/)[1];
                    // watch for option list change
                    scope.$watchCollection(list, function(items) {
                        recreateSelect();
                    });
                }

                if (attrs.ngDisabled) {
                    scope.$watch(attrs.ngDisabled, refreshSelect);
                }

                function extendSelect2() {

                    if (!s2Obj) return;

                    let elId = s2Obj.$element[0].id;
                    if (elId) {
                        //add element label to aria label
                        s2Obj.$selection.attr('aria-labelledby', 'label-'+elId+' '+ s2Obj.$selection.attr('aria-labelledby'));

                    }

                    //fix for https://github.com/select2/select2/issues/4417
                    s2Obj.results.highlightFirstItem = function() { return false; };

                    //match has-* bootstrap classes of parent for dropdown
                    s2Obj.on( "open", function() {
                        var $hasClosest, $s2DropDown, hasExisting, hasNew;

                        $hasClosest = $(s2Obj.$element).closest("[class*='has-']");
                        $s2DropDown = $( "body > .select2-container" );

                        //remove current has-* classes
                        hasExisting = $s2DropDown[0].className.match(/has-[a-z]+/g);
                        if (hasExisting) {
                            $s2DropDown.removeClass(hasExisting.join(" "));
                        }

                        if ($hasClosest.length) {
                            hasNew = $hasClosest[0].className.match(/has-[a-z]+/g);
                            if (hasNew) {
                                $s2DropDown.addClass(hasNew.join(" "));
                            }
                        }
                    });

                    if (modelController) {
                        s2Obj.on('blur', function (evt) {
                            modelController.$setTouched();
                            scope.$apply();
                        });

                        //temporary fix for https://github.com/select2/select2/issues/4384
                        //#4384 has been closed, this can be removed
                        // s2Obj.$element.on(
                        //     'select2:close',(
                        //         function(){
                        //             $(this).focus();
                        //         }
                        //     )
                        // );
                    }

                    //temporary fix for https://github.com/select2/select2/issues/4475
                    s2Obj.$selection.on('mousedown', '.select2-selection__clear', function (evt) {
                        s2Obj.close();
                        s2Obj.$element.val(null).trigger('change');
                        evt.preventDefault();
                    });

                    delete s2Obj.listeners.keypress;
                    s2Obj.on('keypress', function (evt) {
                        // https://github.com/select2/select2/issues/3472#issuecomment-132246474
                        var key = evt.which;
                        var val;

                        if (s2Obj.isOpen()) {
                            if (key === KEYS.ENTER) {
                                if (s2Obj.options.options.multiple) {
                                    s2Obj.trigger('results:toggle');
                                } else {
                                    s2Obj.trigger('results:select');
                                }
                                evt.preventDefault();
                            } else if ((key === KEYS.SPACE && evt.ctrlKey)) {
                                s2Obj.trigger('results:toggle');

                                evt.preventDefault();
                            } else if (key === KEYS.UP) {
                                s2Obj.trigger('results:previous');

                                evt.preventDefault();
                            } else if (key === KEYS.DOWN) {
                                s2Obj.trigger('results:next');

                                evt.preventDefault();
                            } else if (key === KEYS.ESC || key === KEYS.TAB) {
                                s2Obj.close();

                                evt.preventDefault();
                            }
                        } else {
                            if (key === KEYS.ENTER || key === KEYS.SPACE ||
                                ((key === KEYS.DOWN || key === KEYS.UP) && evt.altKey)) {
                                s2Obj.open();
                                evt.preventDefault();
                                return;
                            }
                            if ((key === KEYS.DOWN || key === KEYS.UP) && s2Obj.options.options.multiple) {
                                s2Obj.open();
                                evt.preventDefault();
                                return;
                            }
                            if ((65 <= key && key <= 90)) {
                                //a-z
                                var keyString = evt.key;
                                s2Obj.open();
                                var $search = s2Obj.dropdown.$search || s2Obj.selection.$search; //single, or multi-select
                                $search.val(keyString);
                                // s2Obj.dropdown.$search.trigger('keyup'); //doesn't trigger?
                                s2Obj.trigger('query', {term:keyString});
                                evt.preventDefault();
                            }
                            if (key === KEYS.DOWN) {
                                val = s2Obj.$element.find('option:selected').nextAll(":enabled").first().val();
                                if (undefined !== val) {
                                    s2Obj.$element.val(val);
                                    s2Obj.$element.trigger('change');
                                }
                                evt.preventDefault();
                            }
                            if (key === KEYS.UP) {
                                val = s2Obj.$element.find('option:selected').prevAll(":enabled").first().val();
                                if (undefined !== val) {
                                    s2Obj.$element.val(val);
                                    s2Obj.$element.trigger('change');
                                }
                                evt.preventDefault();
                            }
                            if (key === KEYS.DELETE || key === KEYS.BACKSPACE) {
                                if (s2Obj.options.options.multiple) {
                                    s2Obj.open();
                                } else  {
                                    s2Obj.$element.val(null).trigger('change');
                                }
                                evt.preventDefault();
                            }
                        }
                    });

                    if (s2Obj.options.options.multiple) {
                        //when removing a multi-select item this will remove the text of the item that was
                        //deleted that's normally put back in the search box
                        //https://github.com/select2/select2/issues/3354
                        s2Obj.on('unselect', function () {
                            $timeout(function () {
                                s2Obj.selection.$search.val('').trigger('change');
                            });
                        });
                    }
                }
            }
        };
    }]);
})();
