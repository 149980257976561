(function() {
    'use strict';
    angular.module('wbUtilities').directive('wbFooter', ['$state', '$timeout', '$window', function ($state, $timeout, $window) {
        return {
            restrict: 'A',
            scope: true,
            link: function (scope, element) {
                scope.currentYear = new Date().getFullYear();
            }
        };
    }]);
})();
