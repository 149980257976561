(function() {
    'use strict';
    angular.module('wbPublic').controller('MPPageController', ['$scope', '$uibModal', 'Request',
function($scope, $uibModal, Request) {

    $scope.init = function() {
        $scope.getAssets().then(function(assetClasses) {
        $scope.assets = assetClasses;
        });
    };

    $scope.getAssets = function() {
        return Request.make('asset_classes').then(function (response) {
            let assetClasses = response.data.response;
            return assetClasses;
        });
    };

    $scope.openAssetModal = function(asset) {

        $scope.asset = asset;

        var modalInstance = $uibModal.open({
            templateUrl: "mp-asset-modal.tpl.html",
            scope: $scope,
            size: 'lg',
            windowClass: 'mp-asset-modal'
        });
        modalInstance.result.catch(angular.noop);

    };

    $scope.init();

}]);
})();
