(function() {
    'use strict';
    angular.module('wbUtilities').constant('Misc', {
        validation: {
            email: /^[_a-z0-9]+([\.\+\-_a-z0-9])*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,24})$/i,
            password: /^(?=.*[\d~!@#$%^&*()_\-+={}[\]\\\/,.<>?\"\';:|])(?=.*[a-z])(?=.*[A-Z])\S{8,}$/,
            oneLower: /[a-z]/,
            oneUpper: /[A-Z]/,
            oneNumber: /[0-9]/,
            oneSpecial: /[^a-zA-Z0-9 ]/,
            ssn: /^(?!(000|666|9))\d{3}-?(?!00)\d{2}-?(?!0000)\d{4}$/,
            fullName: /^[a-z'-]* [a-z' -]*$/i,
            alpha: /^[a-z]*$/i,
            number: /^\d+(\.\d+)?$/,
            alphaSpace: /^[a-z ]*$/i,
            alphaNum: /^[0-9a-z]*$/i,
            alphaNumSpace: /^[0-9a-z ]*$/i,
            alphaNumPlus: /^[0-9a-z ',#\.\/-]*$/i,
            poBox: /^ *((#\d+)|((box|bin)[-. \/\\]?\d+)|(.*p[ \.]? ?(o|0)[-. \/\\]? *-?((box|bin)|b|(#|num)?\d+))|(.*p(ost)? *(o(ff(ice)?)?)? *((box|bin)|b)? *#?\d+)|(p *-?\/?(o)? *-?b(ox|(#?\d+)))|post office box|((box|bin)|b) *(number|num|#)? *\d+|(num|number|#) *\d+)/i,
            tester: function(value = "", regex) {
                //for use as ui-validate function
                if (!(regex instanceof RegExp)) {
                    regex = new RegExp(regex);
                }
                return regex.test(value);
            }
        },
        states: [
            { name: 'Alabama', abbreviation: 'AL'},
            { name: 'Alaska', abbreviation: 'AK'},
            { name: 'Arizona', abbreviation: 'AZ'},
            { name: 'Arkansas', abbreviation: 'AR'},
            { name: 'California', abbreviation: 'CA'},
            { name: 'Colorado', abbreviation: 'CO'},
            { name: 'Connecticut', abbreviation: 'CT'},
            { name: 'Delaware', abbreviation: 'DE'},
            { name: 'District of Columbia', abbreviation: 'DC'},
            { name: 'Florida', abbreviation: 'FL'},
            { name: 'Georgia', abbreviation: 'GA'},
            { name: 'Hawaii', abbreviation: 'HI'},
            { name: 'Idaho', abbreviation: 'ID'},
            { name: 'Illinois', abbreviation: 'IL'},
            { name: 'Indiana', abbreviation: 'IN'},
            { name: 'Iowa', abbreviation: 'IA'},
            { name: 'Kansas', abbreviation: 'KS'},
            { name: 'Kentucky', abbreviation: 'KY'},
            { name: 'Louisiana', abbreviation: 'LA'},
            { name: 'Maine', abbreviation: 'ME'},
            { name: 'Maryland', abbreviation: 'MD'},
            { name: 'Massachusetts', abbreviation: 'MA'},
            { name: 'Michigan', abbreviation: 'MI'},
            { name: 'Minnesota', abbreviation: 'MN'},
            { name: 'Mississippi', abbreviation: 'MS'},
            { name: 'Missouri', abbreviation: 'MO'},
            { name: 'Montana', abbreviation: 'MT'},
            { name: 'Nebraska', abbreviation: 'NE'},
            { name: 'Nevada', abbreviation: 'NV'},
            { name: 'New Hampshire', abbreviation: 'NH'},
            { name: 'New Jersey', abbreviation: 'NJ'},
            { name: 'New Mexico', abbreviation: 'NM'},
            { name: 'New York', abbreviation: 'NY'},
            { name: 'North Carolina', abbreviation: 'NC'},
            { name: 'North Dakota', abbreviation: 'ND'},
            { name: 'Ohio', abbreviation: 'OH'},
            { name: 'Oklahoma', abbreviation: 'OK'},
            { name: 'Oregon', abbreviation: 'OR'},
            { name: 'Pennsylvania', abbreviation: 'PA'},
            { name: 'Rhode Island', abbreviation: 'RI'},
            { name: 'South Carolina', abbreviation: 'SC'},
            { name: 'South Dakota', abbreviation: 'SD'},
            { name: 'Tennessee', abbreviation: 'TN'},
            { name: 'Texas', abbreviation: 'TX'},
            { name: 'Utah', abbreviation: 'UT'},
            { name: 'Vermont', abbreviation: 'VT'},
            { name: 'Virginia', abbreviation: 'VA'},
            { name: 'Washington', abbreviation: 'WA'},
            { name: 'West Virginia', abbreviation: 'WV'},
            { name: 'Wisconsin', abbreviation: 'WI'},
            { name: 'Wyoming', abbreviation: 'WY' },
            { name: 'Puerto Rico', abbreviation: 'PR' }
        ],
        employment_status: [
            {name:'Employed', value:'employed'},
            {name:'Self-Employed', value:'self-employed'},
            {name:'Unemployed', value:'unemployed'},
            {name:'Retired', value:'retired'},
            {name:'Student', value:'student'}
        ],
        occupations: [
            {
                value:'Administrative',
                name:'Administrative'
            },
            {
                value:'Business_Owner',
                name:'Business Owner'
            },
            {
                value:'Consulting',
                name:'Consulting'
            },
            {
                value:'Creative',
                name:'Creative'
            },
            {
                value:'Education',
                name:'Education'
            },
            {
                value:'Engineering',
                name:'Engineering'
            },
            {
                value:'Finance',
                name:'Finance'
            },
            {
                value:'Health_Care',
                name:'Health Care'
            },
            {
                value:'Homemaker',
                name:'Homemaker'
            },
            {
                value:'Human_Resources',
                name:'Human Resources'
            },
            {
                value:'Information_Systems',
                name:'Information Systems'
            },
            {
                value:'Legal',
                name:'Legal'
            },
            {
                value:'Management',
                name:'Management'
            },
            {
                value:'Marketing',
                name:'Marketing'
            },
            {
                value:'Operations',
                name:'Operations'
            },
            {
                value:'Other',
                name:'Other'
            },
            {
                value:'Sales',
                name:'Sales'
            },
            {
                value:'Service',
                name:'Service'
            },
            {
                value:'Training',
                name:'Training'
            }
        ],
        income_levels: [
            {
                value: 1,
                name:"Less than $5,000",
                bin: 0.00
            },
            {
                value: 2,
                name:"$5,000 - $25,000",
                bin: 5000.00
            },
            {
                value: 3,
                name:"$25,000 - $50,000",
                bin: 25000.00
            },
            {
                value: 4,
                name:"$50,000 - $100,000",
                bin: 50000.00
            },
            {
                value: 5,
                name:"$100,000 - $250,000",
                bin: 100000.00
            },
            {
                value: 6,
                name:"$250,000 - $500,000",
                bin: 250000.00
            },
            {
                value: 7,
                name:"$500,000 - $1 million",
                bin: 500000.00
            },
            {
                value: 8,
                name:"$1 million +",
                bin: 1000000.00
            }
        ],
        account_types: [
            'Personal Investment Account',
            'Roth IRA',
            'SEP IRA',
            'Traditional IRA'
        ],
        bank_account_types: [
            'checking',
            'savings'
        ],
        account_source_types: [
            "Create New Account",
            "Rollover Outside Account"
        ],
        deposit_frequencies : [
            {
                value: "weekly",
                label: "Week"
            },
            {
                value: "monthly",
                label: "Month"
            },
        ],
        suffixes: [
            'Jr','Sr','II','III','IV'
        ]
    });
})();
