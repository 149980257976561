(function() {
    'use strict';
    angular.module('wbUtilities').directive('clickEnter', ['$timeout', '$interpolate', function ($timeout, $interpolate) {
        return {
            link: function (scope, element, attrs) {
                element.bind("keydown", function (event) {
                    if (event.which === 13) {
                        $(this).trigger('click');
                    }
                    // if (['checkbox','radio'].indexOf(element[0].type) !== -1 && event.which === 32) {
                    //     //this doesn't actually trigger the model change if there's a ng-click defined
                    //     $(this).trigger('click');
                    // }
                });
            }
        };
    }]);
})();
