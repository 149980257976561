(function() {
    'use strict';
    angular.module('wbUtilities').factory('Request',
['$http', '$q', '$timeout', '$location', 'Auth', 'CommonConstant',
function ($http, $q, $timeout, $location, Auth, CommonConstant) {

        var cache = false;
        var localMicro = false;

        var domain = "wisebanyan.com";
        if ($location.host().includes('axosinvest')) {
            domain = "axosinvest.com";
        }

        var request = {
                disableAuth: false,
                defaults: {
                    method: "GET",
                    cancel_http: null,
                    host: "https://gateway-api." + domain,
                    base: "api/v1",
                    base2: "api/v2",
                    base3: "api/v3",
                }
            };

        if (CommonConstant.devDomains.includes($location.host())) {
            // request.defaults.host = "https://development-gateway-api." + domain;
            request.defaults.host = "https://staging-gateway-api." + domain;
            if (localMicro) {
                request.defaults.host = "https://gate.dev:8686/app_dev.php";
            }
            if (cache) {
                request.defaults.host = "http://localhost:3000";
            }
        }

        var routes =
            {
                signup_request: {
                    url: "signups",
                    method: "POST",
                    base: 3
                },
                signup_init: {
                    url: "signups/{code}",
                    method: "GET",
                    base: 3
                },
                signup_update: {
                    url: "signups/{code}/progress",
                    method: "PUT",
                    base: 3
                },
                signup_complete: {
                    url: "signups/{code}/complete",
                    method: "PUT",
                    base: 3
                },
                signup_verify_email: {
                    url: "signups/{code}/verify-email",
                    method: "PUT",
                    base: 3
                },
                signup_plaid_add_token: {
                    url: "signups/plaid-add-token",
                    method: "POST",
                    auth: false,
                    base: 3
                },
                signup_calculate_risk: {
                    url: "surveys/money-mindset",
                    method: "POST",
                    base: 3
                },

                signup_axos: {
                    url: "axos-signup",
                    method: "POST",
                    base: 3
                },


                signup_referral_name: {
                    url: "invite",
                    method: "GET"
                },
                signup_invite_reminders: {
                    url: "invite/reminders/{code}",
                    method: "GET"
                },
                signup_invite_reminders_send: {
                    url: "invite/reminders",
                    method: "POST"
                },
                signup_invite_unsubscribe: {
                    url: "invite/unsubscribe",
                    method: "POST"
                },

                public_calculator: {
                    url: "calculator/{msType}",
                    method: "POST",
                    base: 3
                },

                /* ==================================================================== */
                user_preferences_email: {
                    url: "users/preferences/email",
                    method: "PUT"
                },
                username_remind: {
                    url: "recover/username",
                    method: "POST",
                    base: 3
                },
                password_remind: {
                    url: "password/remind",
                    method: "POST"
                },
                password_reset: {
                    url: "password/reset",
                    method: "POST"
                },
                auth_refresh: {
                    url: "auth/refresh",
                    method: "GET",
                    base : 3
                },
                auth: {
                    url: "auths",
                    method: "POST",
                    base : 3
                },
                auth_user: {
                    url: "auth/user",
                    method: "GET",
                    base : 3
                },
                auth_property_bag: {
                    url: "auths/property_bag/{guid}",
                    method: "GET",
                    base : 3
                },
                auth_axos_token: {
                    url: "users/axos-token",
                    method: "GET",
                    base : 3,
                    auth: true
                },
                otp_send: {
                    url: "otps/sends",
                    method: "POST",
                    base : 3,
                    auth: true
                },
                otp_validate: {
                    url: "otps/validates",
                    method: "POST",
                    base : 3,
                    auth: true
                },
                /* ==================================================================== */
                security_migrate: {
                    url: "users/migration",
                    method: "POST",
                    base : 3,
                    auth: true
                },
                security_migrate_status: {
                    url: "users/migration-status",
                    method: "GET",
                    base : 3,
                    auth: true
                },
                security_migrate_verify: {
                    url: "users/migration-credentials",
                    method: "PUT",
                    base : 3,
                    auth: true
                },
                /* ==================================================================== */
                welcome: {
                    url: "users/welcome",
                    method: "GET",
                    auth: true
                },
                /* ==================================================================== */
                user_name_verify: {
                    url: "users/verify-username",
                    method: "POST",
                    base : 3,
                    auth: true
                },
                user_overview: {
                    url: "users/user/overview?include_pending=1",
                    method: "GET",
                    auth: true,
                    base: 2
                },
                user_detail: {
                    url: "users/details",
                    method: "GET",
                    auth: true,
                    base: 3
                },
                user_close: {
                    url: "users/user/close",
                    method: "PUT",
                    auth: true
                },
                user_update: {
                    url: "users",
                    method: "PUT",
                    auth: true,
                    base: 3
                },
                user_performance: {
                    url: "users/user/performance",
                    method: "GET",
                    auth: true
                },
                user_new_performance: {
                    url: "users/performance",
                    method: "GET",
                    auth: true,
                    base: 3
                },
                user_receipts: {
                    url: "users/receipts",
                    method: "GET",
                    auth: true
                },
                user_update_password: {
                    url: "password/change",
                    method: "POST",
                    auth: true
                },
                user_update_frequency: {
                    url: "users/frequency",
                    method: "PUT",
                    auth: true
                },
                user_prospectus: {
                    url: "users/prospectus_links",
                    method: "GET",
                    auth: true,
                    base: 3
                },
                user_documents: {
                    url: "users/documents",
                    method: "GET",
                    auth: true
                },
                user_document_requests: { //replaces uploaded_documents
                    url: "users/document-requests",
                    method: "GET",
                    auth: true,
                    base: 3
                },
                user_document_requests_submit: {
                    url: "users/document-requests/{uuid}/submit",
                    method: "PUT",
                    auth: true,
                    base: 3
                },
                user_document: {
                    url: "users/documents/download",
                    method: "POST",
                    auth: true
                },
                user_document_delete: {
                    url: "users/documents/{document}",
                    method: "DELETE",
                    auth: true
                },
                user_invites: {
                    url: "users/invites",
                    method: "GET",
                    auth: true
                },
                user_referrals: {
                    url: "users/referrals",
                    method: "GET",
                    auth: true
                },
                user_refer: {
                    url: "users/referrals",
                    method: "POST",
                    auth: true
                },
                user_feedback: {
                    url: "feedback",
                    method: "POST",
                    auth: true
                },
                user_sign: {
                    url: "users/agreement",
                    method: "POST",
                    auth: true
                },
                user_email_preferences: {
                    url: "users/preferences/email",
                    method: "GET",
                    auth: false
                },
                user_email_preferences_update: {
                    url: "users/preferences/email",
                    method: "PUT",
                    auth: false
                },
                user_notifications: {
                    url: "users/user/notifications",
                    method: "GET",
                    auth: true
                },
                user_notifications_read: {
                    url: "users/user/notifications/{notification_id}/read",
                    method: "POST",
                    auth: true
                },
                user_send_verify_email: {
                    url: "users/send-email-verification",
                    method: "POST",
                    auth: true,
                    base: 3
                },
                /* ==================================================================== */

                plans: {
                    url: "users/plans",
                    method: "GET",
                    base: 3
                },
                plans_add: {
                    url: "users/plans/{slug}",
                    method: "PUT",
                    base: 3
                },
                plans_remove: {
                    url: "users/plans/{slug}/cancel",
                    method: "PUT",
                    base: 3
                },
                plans_feature_toggle: {
                    url: "users/features/{slug}/{action}",
                    method: "PUT",
                    base: 3
                },

                user_feature_selective_assets: {
                    url: "users/assets",
                    method: "GET",
                    auth: true,
                    base: 2
                },
                user_feature_selective_assets_save: {
                    url: "users/assets",
                    method: "POST",
                    auth: true,
                    base: 2
                },
                user_feature_selective_assets_default: {
                    url: "users/assets/default",
                    method: "GET",
                    auth: true,
                    base: 2
                },
                user_metrics: {
                    url: "users/metrics/{metric}",
                    method: "GET",
                    auth: true,
                },
                /* ==================================================================== */
                portfolios: {
                    url: "portfolios?type={type}",
                    method: "GET",
                    auth: true,
                },
                portfolio_detail: {
                    url: "portfolios/{pslug}/description",
                    method: "GET",
                    auth: true,
                },
                user_portfolios: {
                    url: "users/user/portfolios",
                    method: "GET",
                    auth: true,
                },
                user_portfolio_save: {
                    url: "users/user/portfolios",
                    method: "POST",
                    auth: true,
                },
                user_portfolio_update: {
                    url: "users/user/portfolios/{pslug}",
                    method: "PUT",
                    auth: true,
                },
                user_portfolio_remove: {
                    url: "users/user/portfolios/{pslug}",
                    method: "DELETE",
                    auth: true,
                },
                user_portfolio_positions: {
                    url: "users/user/portfolios/{pslug}/positions",
                    method: "GET",
                    auth: true,
                },
                user_portfolio_metrics: {
                    url: "users/user/portfolios/{pslug}/metrics",
                    method: "GET",
                    auth: true,
                },
                user_portfolio_milestone_allocation: {
                    url: "users/milestones/{msId}/allocation",
                    method: "PUT",
                    auth: true,
                    base: 3
                },
                /* ==================================================================== */
                user_rollovers: {
                    url: "users/user/rollovers",
                    method: "GET",
                    auth: true,
                },
                user_rollover_new: {
                    url: "users/user/rollovers",
                    method: "POST",
                    auth: true,
                },
                user_rollover_update: {
                    url: "users/user/rollovers/{rollover_id}",
                    method: "PUT",
                    auth: true,
                },
                user_rollover_next: {
                    url: "users/user/rollovers/{rollover_id}/complete-step",
                    method: "PUT",
                    auth: true,
                },
                user_rollover_delete: {
                    url: "users/user/rollovers/{rollover_id}/status/canceled",
                    method: "PUT",
                    auth: true,
                },
                user_rollover_forecast: {
                    url: "users/user/rollovers/{rollover_id}/forecast-value",
                    method: "GET",
                    auth: true,
                },
                /* ==================================================================== */
                user_surveys_info: {
                    url: "users/surveys-active",
                    method: "GET",
                    auth: true,
                    base: 3
                },
                user_survey: {
                    url: "users/surveys/{sId}",
                    method: "GET",
                    auth: true,
                    base: 3
                },
                user_surveys_completed: {
                    url: "users/surveys-completed",
                    method: "GET",
                    auth: true,
                    base: 3
                },
                user_surveys_save: {
                    url: "users/surveys/{sId}",
                    method: "PUT",
                    auth: true,
                    base: 3
                },
                /* ==================================================================== */
                user_risk_update: {
                    url: "users/risk-score",
                    method: "PUT",
                    auth: true,
                    base: 3
                },

                /* ==================================================================== */
                user_recommendations: {
                    url: "users/recommendations",
                    method: "GET",
                    auth: true,
                    base: 3
                },
                user_recommendation_slug: {
                    url: "users/recommendations/{slug}",
                    method: "GET",
                    auth: true,
                    base: 3
                },
                user_recommendation_dismiss: {
                    url: "users/recommendations/{slug}/dismiss",
                    method: "PUT",
                    auth: true,
                    base: 3
                },
                user_recommendation_snooze: {
                    url: "users/recommendations/{slug}/snooze",
                    method: "PUT",
                    auth: true,
                    base: 3
                },
                user_recommendation_complete: {
                    url: "users/recommendations/{slug}/complete",
                    method: "PUT",
                    auth: true,
                    base: 3
                },
                user_future_graph: {
                    url: "users/recommend/graphs",
                    method: "GET",
                    auth: true
                },
                user_future_retirement_graph: {
                    url: "users/recommend/graphs/retirement",
                    method: "GET",
                    auth: true
                },
                /* ==================================================================== */
                user_activity: {
                    url: "users/activity",
                    method: "POST",
                    auth: true,
                    base: 3
                },
                user_activity_id: {
                    url: "users/activity/{uuid}",
                    method: "GET",
                    auth: true,
                    base: 3
                },
                user_activity_pending: {
                    url: "users/pending-activity",
                    method: "GET",
                    auth: true,
                    base: 3
                },
                user_transaction_cancel_deposit: {
                    url: "users/accounts/{accountNumber}/transactions/{uuid}/cancel/deposit",
                    method: "POST",
                    auth: true,
                    base: 3
                },
                user_transaction_cancel_withdraw: {
                    url: "users/accounts/{accountNumber}/transactions/{uuid}/cancel/withdraw",
                    method: "POST",
                    auth: true,
                    base: 3
                },
                user_transaction_cancel_xfer: {
                    url: "users/accounts/{accountNumber}/transfers/{uuid}/cancel",
                    method: "PUT",
                    auth: true,
                    base: 3
                },
                user_auto_deposit_schedule: {
                    url: "users/auto-deposit-schedule",
                    method: "GET",
                    auth: true,
                    base: 3
                },
                user_auto_deposit_skip_date: {
                    url: "users/auto-deposit-next",
                    method: "POST",
                    auth: true,
                    base: 3
                },
                /* ==================================================================== */
                formulas_available: {
                    url: "users/recommend/formulas",
                    method: "GET",
                    auth: true,
                },
                formulas_auto_recommend: {
                    url: "users/user/formulas/{formulaSlug}/recommended-deposits",
                    method: "PUT",
                    auth: true,
                },
                user_formulas: {
                    url: "users/user/formulas",
                    method: "GET",
                    auth: true,
                },
                user_formulas_save: {
                    url: "users/user/formulas/{formulaSlug}",
                    method: "PUT",
                    auth: true,
                },
                user_formulas_delete: {
                    url: "users/user/formulas/{formulaSlug}",
                    method: "DELETE",
                    auth: true,
                },
                /* ==================================================================== */
                student_refi_interest: {
                    url: "users/planning/student-refinance/interest",
                    method: "GET",
                    auth: true,
                    base: 3,
                },
                student_refi_savings: {
                    url: "users/planning/student-refinance/savings",
                    method: "GET",
                    auth: true,
                    base: 3,
                },
                /* ==================================================================== */
                mortgage_refi_interest: {
                    url: "users/planning/mortgage-refinance/interest",
                    method: "GET",
                    auth: true,
                    base: 3,
                },
                mortgage_refi_savings: {
                    url: "users/planning/mortgage-refinance/savings",
                    method: "GET",
                    auth: true,
                    base: 3,
                },
                mortgage_refi_recommend: {
                    url: "users/planning/mortgage-refinance/recommend",
                    method: "GET",
                    auth: true,
                    base: 3,
                },
                mortgage_orig_savings: {
                    url: "users/planning/mortgage-origination/savings",
                    method: "GET",
                    auth: true,
                    base: 3,
                },
                mortgage_orig_recommend: {
                    url: "users/planning/mortgage-origination/recommend",
                    method: "GET",
                    auth: true,
                    base: 3,
                },
                /* ==================================================================== */
                haven_quote: {
                    url: "haven/quote",
                    method: "GET",
                    auth: true,
                },
                haven_jwt: {
                    url: "haven/jwt",
                    method: "GET",
                    auth: true,
                },
                /* ==================================================================== */
                milestones: {
                    url: "users/milestones",
                    method: "GET",
                    auth: true,
                    base: 3
                },
                milestone: {
                    url: "users/milestones/{msId}",
                    method: "GET",
                    auth: true,
                    base: 3
                },
                milestone_create: {
                    url: "axos/users/milestones",
                    method: "POST",
                    auth: true,
                    base: 3
                },
                milestone_update: {
                    url: "axos/users/milestones/{msId}",
                    method: "PUT",
                    auth: true,
                    base: 3
                },
                /* ==================================================================== */
                milestone_available: {
                    url: "users/milestones/recommended",
                    method: "GET",
                    auth: false,
                    base: 3
                },
                milestone_allocation_recommend: {
                    url: "users/recommend/allocation",
                    method: "GET",
                    auth: false
                },
                milestone_glide_recommend: {
                    url: "users/glide-path/recommended-allocations",
                    method: "GET",
                    auth: false,
                    base: 3
                },
                milestone_glide_future: {
                    url: "users/glide-path/future-allocation",
                    method: "POST",
                    auth: true,
                    base: 3
                },
                milestone_glide_portfolio_recommend: {
                    url: "users/glide-path/recommended-portfolio",
                    method: "GET",
                    auth: false,
                    base: 3
                },
                milestone_cash_recommend: {
                    url: "users/recommend/cash_allocation",
                    method: "GET",
                    auth: false
                },
                milestone_available_accounts: {
                    url: "users/milestones/{msId}/available-accounts",
                    method: "GET",
                    auth: true
                },
                milestone_add_account: {
                    url: "axos/users/milestones/{msId}/accounts",
                    method: "POST",
                    auth: true
                },
                milestone_delete: {
                    url: "users/milestones/{msId}/delete",
                    method: "PUT",
                    auth: true
                },
                /* ==================================================================== */
                update_initial_deposit: {
                    url: "users/user/initial-deposit",
                    method: "POST",
                    auth: true
                },
                milestone_auto_recommend: {
                    url: "users/recommend/auto-deposit/{msType}",
                    method: "GET",
                    auth: false
                },
                milestone_new_auto_recommend: {
                    url: "users/milestone-recommended-auto-deposit/{msType}",
                    method: "GET",
                    auth: false,
                    base: 3
                },
                milestone_estimated_achievement: {
                    url: "users/milestone-estimated-achievement/{msType}",
                    method: "GET",
                    auth: false,
                    base: 3
                },
                milestone_deposit: {
                    url: "axos/users/milestones/deposit",
                    method: "POST",
                    auth: true,
                    base: 3
                },
                milestone_withdraw: {
                    url: "axos/users/milestones/withdraw",
                    method: "POST",
                    auth: true,
                    base: 3
                },
                milestone_transfer: {
                    url: "users/milestones/{msId}/transfer",
                    method: "POST",
                    auth: true,
                    base: 3
                },
                /* ==================================================================== */
                milestone_funding_activity: {
                    url: "users/milestones/funding-activity",
                    method: "GET",
                    auth: true
                },
                milestone_positions: {
                    url: "users/milestones/{msId}/positions",
                    method: "GET",
                    auth: true,
                    base: 2
                },
                milestone_history: {
                    url: "users/milestones/{msId}/history",
                    method: "GET",
                    auth: true,
                    base: 2
                },
                milestone_future_graph: {
                    url: "users/milestones/{msId}/graphs/future",
                    method: "GET",
                    auth: true,
                    base: 3
                },
                /* ==================================================================== */

                accounts: {
                    url: "users/accounts",
                    method: "GET",
                    auth: true
                },
                /* ==================================================================== */

                user_ira_forecast: {
                    url: "users/user/ira-contribution-forecast",
                    method: "GET",
                    auth: true
                },
                user_annual_deposit_limits: {
                    url: "users/user/annual-deposit-limits",
                    method: "PUT",
                    auth: true
                },

                user_autos: {
                    url: "users/user/auto_deposit",
                    method: "GET",
                    auth: true
                },
                user_autos_update: {
                    url: "users/user/auto_deposit/frequency",
                    method: "PUT",
                    auth: true
                },
                banks: {
                    url: "users/bank_accounts",
                    method: "GET",
                    auth: true,
                    base: 3
                },
                bank_add: {
                    url: "users/bank_accounts",
                    method: "POST",
                    auth: true,
                    base: 3
                },
                bank_cancel: {
                    url: "users/bank_accounts/cancel",
                    method: "PUT",
                    auth: true,
                    base: 3
                },
                bank_confirm_micros: {
                    url: "users/bank_accounts/confirm-micros",
                    method: "PUT",
                    auth: true,
                    base: 3
                },
                bank_plaid_update_micros: {
                    url: "users/banks/{uuid}/micros-entered/{status}",
                    method: "POST",
                    auth: true,
                    base: 3
                },
                bank_plaid_token: {
                    url: "users/banks/{uuid}/public-token",
                    method: "POST",
                    auth: true,
                    base: 3
                },
                bank_plaid_add_token: {
                    url: "users/banks/plaid-add-token",
                    method: "POST",
                    auth: true,
                    base: 3
                },
                bank_plaid_logo: {
                    url: "users/bank_accounts/institutions/{institutionId}",
                    method: "GET",
                    base: 3
                },
                bank_relink: {
                    url: "users/banks/{bankId}/relink_bank_account",
                    method: "POST",
                    auth: true
                },
                account_all_trades: {
                    url: "users/trade_history",
                    method: "GET",
                    auth: true,
                    base: 3
                },
                account_ira_assist: {
                    url: "users/accounts/{account}/ira_assist",
                    method: "GET",
                    auth: true
                },
                /* ==================================================================== */
                plaid_status: {
                    url: "plaid/status",
                    method: "GET",
                    auth: false
                },
                plaid_default: {
                    url: "plaid/institutions/default",
                    method: "GET",
                    auth: false,
                    base: 2
                },
                plaid_search: {
                    url: "plaid/institutions/search/{query}",
                    method: "GET",
                    auth: false,
                    base: 2
                },
                plaid_webhook: {
                    url: "webhooks/plaid/users/{inviteCode}/micro-bank",
                    method: "POST",
                    auth: false,
                    base: 3
                },
                /* ==================================================================== */
                asset_classes: {
                    url: "asset_classes",
                    method: "GET",
                    auth: false,
                },
                risk_score_models: {
                    url: "risk_score_models",
                    method: "GET",
                    base: 2
                },

            };

        request.url = function (route, data) {
            var token = Auth.getToken(),
                urlPath = routes[route].url,
                isAuth = routes[route].auth ? true : false,
                requiredParams = ["account", "document", "msId", "msType"],
                base = request.defaults.base
            ;

            if (isAuth) {
                requiredParams.push("token");
            }

            /*jshint -W030 */
            data || (data = {});
            /*jshint +W030 */

            var urlParameters = urlPath.match(/{[^}]*}/g);
            angular.forEach(urlParameters, function(param, index) {
                param = param.match(/{(.*)}/)[1];
                urlParameters[index] = param;
                if (param in data) {
                    urlPath = urlPath.replace("{"+param+"}", data[param]);
                    delete data[param];
                } else {
                    if (param in requiredParams) {
                        throw new Error("RequestService: URL Request missing " + param);
                    } else {
                        //remove the param without anything
                        urlPath = urlPath.replace("{"+param+"}", "");
                    }
                }
            });

            //for routes that have a different base route
            if (routes[route].base && !isNaN(routes[route].base)) {
                base = request.defaults['base'+routes[route].base];
            }

            return request.defaults.host + "/" + base + "/" + urlPath;

        };

        request.make = function (route, data = {}) {
            if (!routes[route]) {
                throw new Error("RequestService: No such request route: " + route);
            }

            var
                method = (typeof routes[route].method !== "undefined") ? routes[route].method : request.defaults.method,
                makeRequestUrl = request.url(route, data),
                token = Auth.getToken(),
                headers = {
                    "x-wisebanyan-app":"desktop",
                    "x-api-key": Auth.getApiKey()
                },
                httpRequest,
                postData = {},
                getData = {}
            ;

            if (routes[route].auth && !token) {
                throw new Error("RequestService: Request is missing token: " + route);
            } else if (token && !request.disableAuth) {
                headers['Auth-Token'] = token;
            }
            // headers['x-wisebanyan-build'] = CommonConstant.build;

            if (cache) {
                headers['x-unique-id'] = Auth.getUsername();
            }

            if (method !== "GET") {
                postData = data;
            } else if (method === "GET") {
                getData = data;
            }

            //for future testing:
            //http://stackoverflow.com/questions/18238227/delay-an-angular-js-http-service
            // console.log("making request: ", makeRequestUrl, " | data: ", data);
            httpRequest = $q.when(1).then(function() {
                if (route === 'auth') {
                    var timeReq = {
                        url: request.defaults.host + "/utils/time",
                        headers: {
                            "x-api-key": Auth.getApiKey()
                        }
                    };
                    if (cache) {
                        timeReq.headers['x-unique-id'] = data.username;
                    }
                    return $http(timeReq).catch(function() {
                        return {data:{body:moment().unix()}};
                    }).then(function(response) {
                        var time = response.data.body;
                        headers['x-wisebanyan-sig'] = Auth.getSigKey(data.username, time);
                        if (CommonConstant.devDomains.includes($location.host())) {
                            //skip captcha
                            headers['x-wisebanyan-app'] = 'ios';
                            if (cache) {
                                headers['x-unique-id'] = data.username;
                            }
                        }
                    });
                }
            }).then(function() {
                if (routes[route].debug === true && window.document.cookie.includes('XDEBUG')) {
                    getData['XDEBUG_SESSION_START'] = 'phpstorm';
                }

                return $http({
                    url: makeRequestUrl,
                    method: method,
                    data: postData,
                    params: getData,
                    timeout: request.cancel_http,
                    headers: headers
                });
            });

            //do after request or data will be deleted in tracking
            //Track.sendRequest(route, data);

            return httpRequest;

        };

        request.route = function (name) {
            var route = routes[name];
            route.method = route.method ? route.method : request.defaults.method;
            return route;
        };

        return request;
    }]);
})();
