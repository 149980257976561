(function() {
    'use strict';
    angular.module('wbUtilities')
        .directive('codeInput', ['$timeout', '$location', function ($timeout, $location) {
        return {
            restrict: 'A',
            scope: {
                code: '=',
                focus: '=?'
            },
            templateUrl: "/common/src/app/directives/code-input/code-input.tpl.html",
            link: function (scope, element, attrs) {
                scope.data = [];

                if (!scope.code) {
                    scope.code = "";
                }

                if (scope.focus) {
                    $timeout(function() {
                        element.find('input').first().focus();
                    }, 1000);
                }

                scope.$watch('code', function(code) {
                    //parent scope code stored as single string
                    if (typeof code === "string" && scope.data.join("") !== code) {
                        scope.data = code.split("");
                    }
                }, true);
                scope.$watch('data', function() {
                    //local array code is stored in from input
                    if (scope.data) {
                        scope.code = scope.data.join("");
                    }
                }, true);

                var inputs = element.find('input');

                element.on('paste', 'input', function(event) {
                    if (event.originalEvent.clipboardData) {
                        var text = event.originalEvent.clipboardData.getData("text/plain");
                        text = text.replace(/[^0-9]/g,"").slice(0,6);
                        $timeout(function() {
                            scope.code = text;
                        });
                    } else {
                        event.preventDefault();
                    }
                });

                element.on('focus click keyup', 'input', function(event) {
                    var $el = angular.element(this);
                    // if ($el.next().length) { //not sure what the purpose of skipping the last box was
                        this.setSelectionRange(0, this.value.length);
                    // }
                });

                //control arrows and delete
                element.on('keydown', 'input', function(event) {
                    var $el = angular.element(this);

                    if ([8, 46].includes(event.which)) { //backspace && delete
                        //on mobile this only triggers when the box is empty

                        //always delete if highlighted or not
                        var empty = !this.value;

                        this.value = null;
                        $el.data('lastGood', null);
                        event.preventDefault();

                        if (empty && $el.prev().length) {
                            $el.prev().focus();
                        }


                        //to update angular - won't have originalEvent
                        $el.trigger('input');
                    }

                    if (event.which === 37 && $el.prev().length) { //left
                        $el.prev().focus();
                    }
                    if (event.which === 39 && $el.next().length) { //right
                        $el.next().focus();
                    }

                    if (/Edge/.test(navigator.userAgent)) {
                        if (event.which === 9) {
                            //want to do nothing on tab
                            return;
                        }

                        if (!(/[0-9]/.test(event.key))) {
                            event.preventDefault();
                        } else {
                            if ($el.next().length) {
                                $timeout(function() {
                                    //trigger next after keyup event
                                    $el.next().focus();
                                });
                            }
                        }
                    }

                });

                element.on('input', 'input', function(event) {
                    //mobile only receives input after item has been entered into field, no keypress key id avail
                    if (/Edge/.test(navigator.userAgent)) {
                        return;
                    }

                    var $el = angular.element(this);

                    if (event.originalEvent) {
                        if (event.originalEvent.inputType.includes('insert')) {
                            if ((/[0-9]/.test(event.originalEvent.data))) {
                                this.value = event.originalEvent.data;
                                $el.data('lastGood', this.value);
                                if ($el.next().length) {
                                    $el.next().focus();
                                }
                            } else {
                                this.value = $el.data('lastGood') || null;
                            }
                            //to update angular - won't have originalEvent
                        }
                        if (event.originalEvent.inputType.includes('delete')) {
                            //on mobile this only triggers if there was a char to be deleted
                            if ($el.prev().length) {
                                //$el.prev().focus();
                            }
                        }

                        $el.trigger('input');
                    }
                });
            }
        };
    }]);
})();
