(function() {
    'use strict';
    //service must be lowercase since Plaid library attaches to window.Plaid
    angular.module('wbUtilities').provider('bugsnagClient', ['$windowProvider', function ($windowProvider) {

        var configDefaults = {};
        var _bugsnagClient;

        var $window = $windowProvider.$get();

        // if a script blocker blocks the bugsnag library Bugsnag will be undefined at this point, so we initialize it to an object
        // with methods that do nothing but are declared and won't throw errors later by the angular-bugsnag
        // module calling them
        var Bugsnag = $window.bugsnag ||
            function() {
                return {
                    notify: angular.noop,
                    user: {},
                };
            };

        // in case bugsnag was initialized before angular loaded, this will set the client to that and extract the key data
        _bugsnagClient = $window.bugsnagClient || { config:{}, user:{}, notify: angular.noop };
        var apiKey = _bugsnagClient.config.apiKey;

        var _self = this;

        /**
         * @param config Object|string can be config object or api key
         * @returns {bugsnag}
         */
        this.init = function(config = {}) {
            if (typeof config === "string") {
                config = {apiKey: config};
            } else if (config && !config.apiKey) {
                config.apiKey = apiKey;
            }

            config = angular.extend({}, configDefaults, config);

            _bugsnagClient = Bugsnag(config);

            if ($window.bugsnagClient) {
                //if it already exists, update it to disable it's options and replace with new one
                $window.bugsnagClient.setOptions({ autoCaptureSessions: false, autoNotify: false, autoBreadcrumbs: false});
                $window.bugsnagClient.configure();
                //clear out existing session
                $window.bugsnagClient._session = null;
                //doesn't really work...
                delete $window.bugsnagClient;
                $window.bugsnagClient = undefined;

                // $window.bugsnagClient = _bugsnagClient;
            }

            return _self;


        };

        this.handleBugsnagException = function(exception, cause = null, $state = null) {
            var error;

            if (!_bugsnagClient || !_bugsnagClient.BugsnagReport) {
                return;
            }

            if (typeof exception === "string") {
                error = new Error(exception);
                error.name = error.message.slice(0,100);
            } else {
                error = exception;
            }
            const handledState = {
                severity: "error",
                severityReason: { type: "unhandledException" },
                unhandled: true,
            };
            const report = new _bugsnagClient.BugsnagReport(
                error.name,
                error.message,
                _bugsnagClient.BugsnagReport.getStacktrace(error),
                handledState,
                error,
            );

            if (typeof exception === "string") {
                report.errorClass = "ErrorString: " + report.errorClass;
            }

            if (cause) {
                report.updateMetaData("angular", {
                    cause: cause,
                });
            }
            if ($state) {
                report.updateMetaData("angular", {
                    state: $state.current,
                    stateParams: $state.params,
                });
            }

            return _bugsnagClient.notify(report);
        };

        this.$get = ['$injector', function ($injector) {
            return _bugsnagClient;
        }];

        return _self;

    }]);
})();
