(function() {
    'use strict';
    angular.module('wbPublic').directive('signupButton', ['$state', '$uibModal', function ($state, $uibModal) {
        return {
            restrict: 'A',
            scope: true,
            link: function ($scope, element, attrs) {

                element.click(function () {
                    $scope.inviteModal();
                });

                $scope.inviteModal = function () {
                    $scope.modalInstance = $uibModal.open({
                        controller: 'InviteFormController',
                        templateUrl: 'app/directives/signup-button/signup-button-modal.tpl.html',
                        scope: $scope,
                        size: 'md',
                        windowClass: 'confirm-modal'
                    });
                    $scope.modalInstance.result.catch(angular.noop);
                };
            }
        };
    }]);
})();
