(function() {
    'use strict';
    angular.module('wbUtilities').factory('Track', ['$timeout', '$window', function ($timeout, $window) {
        var track = {};
        var mixpanel = $window.mixpanel;
        var adRoll   = $window.__adroll;
        var adRollTimeout = false;

        if (!mixpanel) {
            mixpanel = {
                identify: function() {},
                track: function() {},
                register: function() {},
            };
        }

        track.setDistinctId = function(distinctId) {
            mixpanel.identify(distinctId);
        };

        track.register = function(key, value) {
            var superProperty = {};
            superProperty[key] = value;
            mixpanel.register(superProperty);
        };

        track.send = function (title, description, data) {
            try {
                var trackData = {};
                if (typeof data === "object") {
                    angular.copy(data, trackData);
                }
                trackData.description = description;
                trackData.platform = "desktop";

                //do not explictly pass distinct id. Mixpanel should take from the cookie automatically

                mixpanel.track(title, trackData);
                return true;
            } catch (err) {
                // console.log(err);
            }
        };

        track.gtm = function(data) {
            if ($window.dataLayer) {
                $window.dataLayer.push(data)
            }
        };

        track.sendRequest = function(request, data) {
            try {
                var trackData = {};
                if (typeof data == "object") {
                    angular.copy(data, trackData);
                }

                switch (request) {
                    case "login":
                        delete trackData.password;
                        break;
                    case "password_reset":
                        delete trackData.password;
                        delete trackData.password_confirmation;
                        break;
                }

                track.send(request, "API Request Key: " + request, trackData);
            } catch (err) {
                // console.log(err);
            }
        };

        track.sendRoute = function(state, params) {
            try {
                var route;
                /*jshint -W030 */
                params || (params = {});
                /*jshint +W030 */
                route = state.name;
                track.send(route, "Route Name: " + route, params);

            } catch (err) {
                // console.log(err);
            }
        };

        track.fbq = function(action, name, data) {
            if (typeof fbq !== "undefined") {

                var trackData = {};
                if (typeof data === "object") {
                    angular.copy(data, trackData);
                }
                trackData.platform = "desktop";

                fbq(action, name, trackData);
            }
        };

        track.trackPage = function(trans) {
            var to = trans.to();
            var from = trans.from();
            var params = trans.params('to');
            var fparams = trans.params('from');
            var misc = {}; //for misc params needed for different pages
            var feature = "";

            switch (params.pslug) {
                case 'taxplan':
                    misc.plan = "Tax Package";
                    break;
                default:
                    misc.plan = params.pslug;
            }

            switch (to.name) {
                case 'base.overview':
                    if (to.name != from.name) {
                        track.send("Page Viewed", "Overview", {page: "Overview View"});
                    }
                    break;
                case 'base.overview.past':
                    track.send("Page Viewed", "Overview", {page: "Past Performance View"});
                case 'base.plans':
                    track.send("Page Viewed", "Services", {page: "Services View"});
                    break;
                case 'base.plans.info':
                    misc.page = planTabName(params['#']);
                    track.send("Page Viewed", "Services", {page: misc.plan + " " + misc.page, package: "Off"});
                    break;
                case 'base.plans.info.feature':
                    misc.feature = featureName(params.fslug);
                    track.send("Page Viewed", "Services", {page: misc.feature + " View", package: "Off"});
                    break;
                case 'base.plans.info.enable':
                    misc.from = from.name;
                    switch (from.name) {
                        case 'base.plans.info':
                            misc.from = planTabName(fparams['#']);
                            break;
                        case 'base.plans.info.feature':
                            misc.from = featureName(fparams.fslug);
                            break;
                    }
                    track.send("Page Viewed", "Services", {page: misc.plan + " Confirm On", from: "from " + misc.from});
                    break;
                case 'base.plans.detail.plan':
                    track.send("Page Viewed", "Services", {page: misc.plan + " Features", package: "On"});
                    break;
                case 'base.plans.detail.plan.disable':
                    track.send("Page Viewed", "Services", {page: misc.plan + " Confirm Off"});
                    break;
                case 'base.formulas':
                    track.send("Formulas Overview", "formulas");
                    break;
                case 'base.planning':
                    track.send("Page Viewed", "planning");
                    break;
                case 'base.planning.survey.complete':
                    track.send("Page Viewed", "planning", {page: "Retake Surveys View"});
                    break;
                case 'base.planning.haven':
                    switch (params.page) {
                        case 'introducing':
                            track.send("Page Viewed", "planning", {page: "Haven Intro View"});
                            break;
                        case 'quote':
                            track.send("Page Viewed", "planning", {page: "Haven Coverage View"});
                            break;
                        case 'submit':
                            track.send("Page Viewed", "planning", {page: "Haven Review Quote View"});
                            break;
                    }
                    break;
                case 'base.planning.studentrefi':
                    switch (params.page) {
                        case 'difficulty':
                            track.send("Page Viewed", "planning", {page: "CommonBond Off Track View"});
                            break;
                        case 'introducing':
                            track.send("Page Viewed", "planning", {page: "CommonBond Intro View"});
                            break;
                        case 'notes':
                            track.send("Page Viewed", "planning", {page: "CommonBond Notes View"});
                            break;
                        case 'adjust':
                            track.send("Page Viewed", "planning", {page: "CommonBond Estimate View"});
                            break;
                        case 'submit':
                            track.send("Page Viewed", "planning", {page: "CommonBond Review View"});
                            break;
                    }
                    break;
                case 'base.planning.mortgage':
                    switch (params.page) {
                        case 'difficulty':
                            track.send("Page Viewed", "planning", {page: "Refinance Off Track View"});
                            break;
                        case 'adjust':
                            track.send("Page Viewed", "planning", {page: "Refinance Estimate View"});
                            break;
                        case 'introducing-axos':
                            track.send("Page Viewed", "planning", {page: "Refinance Intro View", partner: "axos"});
                            break;
                        case 'introducing-no-one':
                            track.send("Page Viewed", "planning", {page: "No Referral Partner View"});
                            break;
                    }
                    break;
                case 'base.planning.mortgage-new':
                    switch (params.page) {
                        case 'adjust':
                            track.send("Page Viewed", "planning", {page: "Origination Estimate View"});
                            break;
                        case 'introducing-axos':
                            track.send("Page Viewed", "planning", {page: "Origination Intro View", partner: "axos"});
                            break;
                        case 'introducing-no-one':
                            track.send("Page Viewed", "planning", {page: "No Referral Partner View"});
                            break;
                    }
                    break;

            }

            function planTabName(tab) {
                switch (tab) {
                    case 'eligibility':
                        return "Eligibility";
                        break;
                    case 'pricing':
                        return "Cost";
                        break;
                    case 'features':
                    default:
                        return "Features";
                        break;
                }
            }
            function featureName(slug) {
                switch (slug) {
                    case 'wiseharvesting':
                        return "WiseHarvesting";
                        break;
                    case 'securityselection':
                        return "Selective Securities";
                        break;
                    case 'rothconversions':
                        return "IRA Conversion";
                        break;
                    default:
                        return "Other Feature" + params.fslug;
                        break;
                }
            }
        };

        return track;
    }]);
})();
