(function() {
    'use strict';
    angular.module('wbUtilities')
.directive('estimateGraph', ['$window', '$timeout', '$filter',
function($window, $timeout, $filter) {
    return {
        restrict: "A",
        scope: {
            data: '=',
            config: '=?',
            label: '@',
            onClick: '&'
        },
        templateUrl: "/common/src/app/directives/estimate-graph/estimate-graph.tpl.html",
        link: function(scope, el, attrs) {
 
            /*jshint -W030 */
            scope.config || (scope.config = {});
            /*jshint +W030 */

            var estimateGraph = new EG(scope.config, el);

            scope.$watch("data", function (data) {
                if (data && data.plotData.length) {
                    //this way data may be manipulated within the graph without triggering external watchers
                    var dataCopy = angular.copy(data);

                    if (!estimateGraph.rendered) {
                        estimateGraph.render();
                    }
                    estimateGraph.refresh(dataCopy);
                }
            });

            // scope.$watch("config", function(c, cOld) {
            //     if (c) {
            //         resize();
            //     }
            // });

            function resize() {
                //on resize rebuild entire graph
                var $svg = el.find('svg');
                if (scope.data && $svg.is(':visible')) {
                    estimateGraph.destroy();
                    estimateGraph.render();
                    estimateGraph.refresh(scope.data);
                }
            }
            angular.element($window).on('resize', resize);

            scope.$on('$destroy', function () {
                clearD3();
            });
            function clearD3() {
                estimateGraph.destroy();
                angular.element($window).off('resize', resize);
            }


            function EG(config, el) {
                var eg = this;
                
                eg.$el = $(el);
                //config items
                eg.charts = [{values:[{x:0, y:0}]}];
                eg.chartsAllMerged = [{x:0, y:0}];

                //object used to return current mouse over location data
                eg.rendered = false;
                eg.configDefault = {
                    height: null,
                    duration: 1000,
                    aspectWidth: 16,
                    aspectHeight: 9,
                    scaleY: true,
                    simpleDates: true,
                    simpleDateResolution: "month",
                    initial: 0,
                    margin: {
                        top: 20,
                        right: 40,
                        bottom: 50,
                        left: 40
                    }
                };
                eg.c = angular.extend({}, eg.configDefault, scope.config);

                //svg elements
                eg.s = {};

                //measurements/calculations
                eg.m = {
                    scale: {x: null, y: null},
                    axis: {},
                    line: null,
                    area: null,
                };

                //initialization of graph
                eg.render = function() {
                    eg.c = angular.extend({}, eg.configDefault, scope.config);

                    eg.measure.init();
                    eg.create.init();

                    eg.rendered = true;

                };

                //calculation methods
                eg.measure = {
                    init: function() {
                        eg.measure.dimensions();
                        eg.measure.axis();
                        eg.measure.paths();
                    },
                    dimensions: function() {
                        let elWidth;

                        try {
                            //often causes a jquery error
                            elWidth = eg.$el.width();
                        } catch(err) {
                            //fallback, close enough, will probably re-render very soon anyway
                            elWidth = $window.innerWidth * 2 / 3;
                        }

                        if (elWidth < 200) { elWidth = 200; }

                        eg.m.aspectWidth = eg.c.aspectWidth;
                        eg.m.aspectHeight = eg.c.aspectHeight;

                        eg.m.margin = eg.c.margin;
                        eg.m.width =  elWidth - eg.m.margin.left - eg.m.margin.right;
                        if (eg.c.height) {
                            if (typeof eg.c.height === "function") {
                                eg.m.height = eg.c.height();
                            } else {
                                eg.m.height = eg.c.height;
                            }
                        } else {
                            eg.m.height = Math.ceil((eg.m.width * eg.m.aspectHeight) / eg.m.aspectWidth) - eg.m.margin.top - eg.m.margin.bottom;
                        }
                        if (eg.m.height < 50) eg.m.height = 50;

                    },
                    axis: function() {
                        eg.m.scale.x = d3.time.scale().range([0, eg.m.width]);
                        eg.m.scale.y = d3.scale.linear().range([eg.m.height, 0]);

                        eg.m.axis.x = d3.svg.axis().scale(eg.m.scale.x).orient("bottom");
                        if (eg.c.simpleDates) {
                            eg.m.axis.x.tickFormat(eg.measure.dateSimpleFormat);
                            eg.setSimpleXAxisLine();
                        }

                        eg.m.axis.y = d3.svg.axis().scale(eg.m.scale.y).orient("left").ticks(5)
                            .tickFormat(function (d) {
                                if (d <= -100 || 100 <= d) {
                                    d = parseFloat(d.toPrecision(3)); //force to 3 sig figs
                                    return d3.format("$s")(d).replace("k", "K");
                                } else if (-100 < d && d < 100) {
                                    return "$" + d.toFixed(1);
                                }
                                return "$" + d;
                            })
                            .innerTickSize(-eg.m.width * 3)
                            .outerTickSize(0)
                            .tickPadding(10);

                    },
                    paths: function() {
                        //main chart chart
                        eg.m.line = d3.svg.line()
                            .interpolate("monotone")
                            .x(function (d) { return eg.m.scale.x(d.x); })
                            .y(function (d) { return eg.m.scale.y(d.y); });

                        //confidence interval wide
                        eg.m.area = d3.svg.area()
                            .interpolate("monotone")
                            .x(function (d) { return eg.m.scale.x(d.x); })
                            .y0(function (d) { return eg.m.scale.y((d.y - eg.c.initial)*0.7+eg.c.initial); })
                            .y1(function (d) { return eg.m.scale.y((d.y - eg.c.initial)*1.3+eg.c.initial); });
                        //confidence interval narrow
                        eg.m.area2 = d3.svg.area()
                            .interpolate("monotone")
                            .x(function (d) { return eg.m.scale.x(d.x); })
                            .y0(function (d) { return eg.m.scale.y((d.y - eg.c.initial)*0.85+eg.c.initial); })
                            .y1(function (d) { return eg.m.scale.y((d.y - eg.c.initial)*1.15+eg.c.initial); });

                        //this is an area and line that are flat so the data can transition from flat when nothing
                        //previously exists
                        eg.m.flatLine = d3.svg.line()
                            .interpolate("monotone")
                            .x(function (d) { return eg.m.scale.x(d.x); })
                            .y(eg.m.height);
                        eg.m.flatArea = d3.svg.area()
                            .interpolate("monotone")
                            .x(function (d) { return eg.m.scale.x(d.x); })
                            .y0(eg.m.height)
                            .y1(eg.m.height);

                    },
                    dateFormat: d3.time.format.multi([
                        [".%L", function(d) { return d.getMilliseconds(); }],
                        [":%S", function(d) { return d.getSeconds(); }],
                        ["%I:%M", function(d) { return d.getMinutes(); }],
                        ["%I %p", function(d) { return d.getHours(); }],
                        ["%a %d", function(d) { return d.getDay() && d.getDate() != 1; }],
                        ["%b %d", function(d) { return d.getDate() != 1; }],
                        ["%b", function(d) { return d.getMonth(); }], //abbreviated month
                        ["%Y", function() { return true; }]
                    ]),
                    dateSimpleFormat: d3.time.format.multi([
                        ["Today", function(d) {
                            var now = new Date();
                            var same;
                            var sameYear = now.getUTCFullYear() === d.getUTCFullYear();
                            var sameMonthYear = now.getUTCFullYear()+""+now.getUTCMonth() === d.getUTCFullYear()+""+d.getUTCMonth();
                            var sameDay = now.getUTCDate() === d.getUTCDate();

                            if (eg.c.simpleDateResolution === "year") {
                                same = sameYear;
                            } else if (eg.c.simpleDateResolution === "month") {
                                same = sameMonthYear;
                            } else if (eg.c.simpleDateResolution === "day") {
                                same = sameMonthYear && sameDay;
                            }

                            return same;

                        }],
                        ["%m/%Y", function(d) {
                            return Math.abs(d.getFullYear() - (new Date()).getFullYear()) < 2;
                        }],
                        ["%m/%Y", function() { return true; }]
                    ])
                };

                //create methods
                eg.create = {
                    init: function() {
                        eg.create.containers();
                        eg.create.chartAddAxis();
                    },
                    containers: function () {

                        var svgContainer = d3.select(eg.$el[0]).select('.svg-container');
                        eg.s.svg = svgContainer.select('svg');


                        //only necessary if not using viewBox
                        eg.s.svg
                            .attr("width", eg.m.width + eg.m.margin.left + eg.m.margin.right)
                            .attr("height", eg.m.height + eg.m.margin.top + eg.m.margin.bottom)
                        ;

                        eg.s.svg.defs = eg.s.svg.append("defs");

                        eg.s.svg.defs.append("clipPath")
                            .attr("id", "graphClip")
                            .append("rect")
                            .attr("width", eg.m.width)
                            .attr("height", eg.m.height)
                        ;
                        //main chart
                        eg.s.main = eg.s.svg.append("g")
                            .attr("class", "focus")
                            .attr("transform", "translate(" + eg.m.margin.left + "," + eg.m.margin.top + ")");

                    },
                    chartAddAxis: function () {
                        eg.s.xAxis = eg.s.main.append("g")
                            .attr("class", "x axis simple")
                            .attr("transform", "translate(0," + eg.m.height + ")")
                            .call(eg.m.axis.x);
                        eg.s.yAxis = eg.s.main.append("g")
                            .attr("class", "y axis")
                            .attr("transform", "translate(0, 0)")
                            .call(eg.m.axis.y);
                    },

                };

                //update methods
                eg.update = {
                    updateGraphElements: function() {

                        //attach charts
                        var maxIndex = 6; //will be able to clear out up to 6 charts if they no longer exist

                        //clear out old data
                        for (var i=maxIndex;i>=0;i--) {
                            if (!eg.charts[i]) {
                                eg.s.main.select(".area-"+i)
                                    .transition()
                                    .duration(eg.c.duration)
                                    .attr("d", eg.m.flatArea).remove();

                                eg.s.main.select(".line-"+i)
                                    .transition()
                                    .duration(eg.c.duration)
                                    .attr("d", eg.m.flatLine).remove();
                            }
                        }

                        //add new data
                        eg.charts.forEach(function(chart, i) {
                            if (!eg.s.main.select(".area-"+i)[0][0]) {
                                eg.s.main.append("path")
                                    .data([eg.charts[i].values])
                                    .attr("class", "area area-" + i)
                                    .attr("clip-path", eg.utility.svgUrl("#graphClip"))
                                    .attr("d", eg.m.flatArea)
                                ;
                                eg.s.main.append("path")
                                    .data([eg.charts[i].values])
                                    .attr("class", "area area2-" + i)
                                    .attr("clip-path", eg.utility.svgUrl("#graphClip"))
                                    .attr("d", eg.m.flatArea)
                                ;
                                eg.s.main.append("path")
                                    .data([eg.charts[i].values])
                                    .attr("class", "line line-" + i)
                                    .attr("clip-path", eg.utility.svgUrl("#graphClip"))
                                    .attr("d", eg.m.flatLine)
                                ;
                            }
                        });
                    },
                    domainRanges: function () {
                        eg.m.scale.x.domain(d3.extent(eg.chartsAllMerged.map(function(d) { return d.x; })));
                        eg.m.scale.y.domain(eg.utility.yExtentAdj(eg.chartsAllMerged));
                    },
                    /**
                     * Data isn't set on initialization, only on refresh
                     * @param {Object} data
                     * @param {Array} data.plotData contains all the graphs to be graphed
                     */
                    setData: function(data) {
                        //update eg.charts by adding or removing multiple graphs till it matches
                        //the same number of graphs getting added
                        var maxIndex = Math.max(eg.charts.length, data.plotData.length) - 1;
                        for (var i=maxIndex;i>=0;i--) {
                            //start from end of array to can remove items from eg.charts
                            if (data.plotData[i]) {
                                eg.charts[i] || (eg.charts[i] = {}); // jshint ignore:line
                                eg.charts[i] = data.plotData[i];
                            } else if (eg.charts[i]) {
                                //deconstruct then remove item
                                eg.charts.splice(i, 1);
                            }
                        }

                        eg.chartsAllMerged = eg.charts.reduce((a, b) => [...a, ...b.values],[]);
                        eg.chartsAllMerged.sort((a,b) => a.x - b.x );

                    }
                };

                //rather than recreate all the elements, use the existing ones so the chart has a smooth transition when eventually updating the data on it
                eg.refresh = function(data) {
                    // http://bl.ocks.org/mbostock/3808234
                    // http://stackoverflow.com/a/33389648/65985


                    //handle 0 or 1 data points
                    angular.forEach(data.plotData, function(plot) {
                        if (!plot.values.length) {
                            var nowDate = moment();
                            plot.values.push({
                                date: nowDate.format("YYYY-MM-DD"),
                                value: null,
                                x: nowDate.valueOf(),
                                y: 0,
                            });
                            plot.values.push(plot.values[0]);
                        } else if (plot.values.length === 1) {
                            //if there's a single data point, duplicate it so graph has start and end points
                            plot.values.push(angular.copy(plot.values[0]));
                            plot.values[1].x += 1; //x requires slightly different coordinate
                        }
                    });

                    eg.update.setData(data);

                    eg.update.domainRanges();

                    eg.setSimpleXAxisLine();

                    //update axis
                    eg.s.main.select(".x.axis").transition().duration(eg.c.duration).call(eg.m.axis.x);
                    eg.s.main.select(".y.axis").transition().duration(eg.c.duration).call(eg.m.axis.y);

                    // //this unfortunately selects the new and old ticks, not just the new ones
                    // var ticks = d3.selectAll(".x.axis .tick");
                    // ticks.attr("class", function(d,i){
                    //     return "tick one";
                    // });


                    eg.update.updateGraphElements();
                    //update graph data
                    eg.charts.forEach(function(chart, i) {
                        eg.s.main.select(".area-"+i).attr("fill", '#4BA9D6');
                        eg.s.main.select(".area2-"+i).attr("fill", '#4BA9D6');

                        eg.s.main.select(".area-"+i).data([chart.values])
                            .transition()
                            .duration(eg.c.duration)
                            .attr("d", eg.m.area);

                        eg.s.main.select(".area2-"+i).data([chart.values])
                            .transition()
                            .duration(eg.c.duration)
                            .attr("d", eg.m.area2);

                        eg.s.main.select(".line-"+i).data([chart.values])
                            .transition()
                            .duration(eg.c.duration)
                            .attr("d", eg.m.line);

                    });


                };


                eg.scaleYByData = function() {
                    //get data only between visible x domain
                    // http://stackoverflow.com/questions/13975237/update-the-y-axis-of-a-brushed-area-chart
                    var fromDate = eg.m.scale.x.domain()[0].getTime();
                    var toDate = eg.m.scale.x.domain()[1].getTime();
                    var dataFiltered = eg.chartsAllMerged.filter(function(d, i) {
                        if ( (d.x >= fromDate) && (d.x <= toDate) ) {
                            return true;
                        }});

                    eg.m.scale.y.domain(eg.utility.yExtentAdj(dataFiltered));

                    var dataY = dataFiltered.map((d)=>d.y);

                };
                eg.setSimpleXAxisLine = function() {
                    eg.m.axis.x.tickValues(eg.m.scale.x.domain());
                };

                eg.clear = function() {
                    var $svg = eg.$el.find('svg');
                    $svg.empty();
                };

                eg.destroy = function() {
                    eg.clear();
                };

                eg.utility = {
                    //add some padding so the graph doesn't hit the top or bottom
                    yExtentAdj: function (data) {
                        var yExtent, yExtentFinal, delta, mean, sum;
                        data = data.map(function(d) {
                            return d.y;
                        });
                        yExtent = d3.extent(data);

                        //make sure not to return [NaN, NaN]
                        if (yExtent.length < 2 || yExtent[0] === undefined || yExtent[1] === undefined) {
                            return [0,0];
                        }
                        delta = yExtent[1] - yExtent[0];

                        yExtentFinal = yExtent.slice(0);


                        if (delta === 0) {
                            yExtentFinal[0] *= 0.8;
                            yExtentFinal[1] *= 1.3; //must be at least as much as confidence interval wide
                        } else {
                            yExtentFinal[0] -= (delta) * 0.2;
                            yExtentFinal[1] += (delta) * 0.3;
                        }
                        if ((yExtent[0] >= 0 && yExtentFinal[0] < 0) || !eg.c.scaleY) {
                            yExtentFinal[0] = 0;
                        }

                        return yExtentFinal;

                    },
                    //converts local url paths to absolute paths to deal with base path issues
                    //https://github.com/angular/angular.js/issues/8934
                    svgUrl: function(path) {
                        return "url('" + $window.location.href.replace(/#.*/, "") + path + "')";
                    },

                };
            }

        }};
    }]);
})();
