(function() {
    'use strict';
    angular.module('wbUtilities').factory('Session', ['localStorageService', function (localStorageService) {
        var session = {};

        session.set = function (name, data) {
            return localStorageService.set(name, data);
        };

        session.get = function (name) {
            return localStorageService.get(name);
        };

        session.keys = function () {
            return localStorageService.keys();
        };

        session.remove = function (name) {
            return localStorageService.remove(name);
        };

        session.clear = function ($regex) {
            return localStorageService.clearAll($regex);
        };

        session.clearCookie = function () {
            return localStorageService.cookie.clearAll();
        };

        session.setCookie = function (name, data, exp) {
            exp = exp ? exp : 30;
            return localStorageService.cookie.set(name, data, exp);
        };

        session.getCookie = function (name) {
            return localStorageService.cookie.get(name);
        };
        
        session.removeCookie = function (name) {
            return localStorageService.cookie.remove(name);
        };
        
        return session;
    }]);
})();
