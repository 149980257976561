(function() {
    'use strict';
    angular.module('wbUtilities').directive('messagingAurea', ['Message', '$timeout', '$window', function (Message, $timeout, $window) {
        return {
            restrict: 'A',
            replace: true,
            scope: true,
            templateUrl: '/common/src/app/directives/messaging/messaging.tpl.html',
            link: function (scope, element) {

                angular.element($window).on('resize', setNavOffset);

                scope.$watchCollection( Message.get, function ( messages ) {
                    scope.messages = messages;
                    scope.showMsg = Message.count() > 0;

                    // $('header nav.navbar').css("marginTop", (Message.count()*2.8)+"rem");
                    $timeout(function() {
                        $timeout(function() {
                            setNavOffset(true);
                        }, 0);
                    }, 0);

                });

                scope.closeMessage = function (index) {
                    Message.clear(index);
                };

                var lastMargin = 0;
                var freshCount = 0;
                var tcount = 0;
                var timePromise;
                function setNavOffset(fresh) {
                    tcount++;
                    if (fresh) {
                        freshCount = 0;
                    }

                    var newMargin = $('.messaging').height();
                    $('header nav.navbar').css("marginTop", newMargin);

                    if (freshCount < 40) {
                        if (timePromise) $timeout.cancel(timePromise);
                        timePromise = $timeout(function() {
                            setNavOffset();
                        }, 10);
                        freshCount++;
                    } else {
                        freshCount = 0;
                        if (Message.count() === 0) {
                            //just in case it didn't run long enough, this'll fix a gap.
                            $('header nav.navbar').css("marginTop", 0);
                        }
                    }
                    // if (Math.abs(lastMargin - newMargin) > 10) {
                    //     debugger;
                    // }

                    lastMargin = newMargin;

                    // console.log(tcount, "fresh", freshCount, "margin", newMargin);

                }

            }
        };
    }]);
})();
