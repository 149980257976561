(function() {
    'use strict';
    //service must be lowercase since Plaid library attaches to window.Plaid
    angular.module('wbPublic').provider('InviteService', [function () {
        /*jshint -W069 */
        var configDefaults = {
        };

        /**
         * @param {Object} options
         * @returns {*}
         */
        this.init = function(options) {
            angular.extend(configDefaults, options);
            configDefaults.onSuccess = function() {};
            return this;
        };


        this.$get = ['$rootScope', 'Request', '$location', '$window', '$q', '$httpParamSerializer', 'Session', 'Track', function($rootScope, Request, $location, $window, $q, $httpParamSerializer, Session, Track) {

            var InviteObj = {
                modelEmail: null
            };

            InviteObj.getSavedReferral = function() {
                return Session.get('referral') || {};
            };
            InviteObj.referral = InviteObj.getSavedReferral();

            InviteObj.getParamHistory = function() {
                return Session.get('paramHistory') || {};
            };

            InviteObj.clearCurrentReferral = function() {
                var referral = InviteObj.referral;
                delete referral.referralCode;
                delete referral.name;
                delete referral.is_affiliate;
                delete referral.message;
                delete referral.alias;
            };
            /**
             * This will save new or existing referral and load it into InviteService.referral
             *
             * 1) check to see if there is a new referral
             * 3) yes new referral ==> retrieve info and replace previous
             * 2) no new referral or bad referral ==> keep previous
             * 4) update alias
             *
             * http://localhost:8081/r/hCGQ6D40S
             * http://localhost:8081/r/zXn5gVXDD
             */
            InviteObj.updateReferral = function() {
                var referral, pathParam, currentAlias, currentReferralCode;

                pathParam = $location.path().match(/\/(r|referral|a)\/([^/]*)/);
                currentReferralCode = (pathParam && pathParam[2]) ? pathParam[2] : "";
                currentAlias = $location.search()['alias'];

                referral = InviteObj.referral;
                referral.badReferralCode = false;

                return $q.when().then(function() {
                    if (currentReferralCode) {
                        return InviteObj.retrieveReferralInfo(currentReferralCode);
                    } else {
                        return $q.reject(false);
                    }
                }).then(function(info) {
                    //good new code
                    referral.referralCode = currentReferralCode;
                    referral.name = info.first_name;
                    referral.is_affiliate = info.is_affiliate;
                    referral.message = InviteObj.getMessage(referral);
                }).catch(function() {
                    //bad new code or no new code
                    //leave referral as it is
                    if (currentReferralCode) {
                        referral.badReferralCode = true;
                    }
                }).then(function() {
                    //work out alias
                    if (referral.is_affiliate) {
                        referral.alias = 'affiliate';
                    } else if (currentAlias && referral.referralCode) {
                        //replace alias if one exists
                        referral.alias = currentAlias;
                    }
                }).finally(function() {
                    Session.set('referral', referral);
                });
            };

            InviteObj.retrieveReferralInfo = function(referralCode, force) {
                var returnPromise;
                var referral = InviteObj.referral;

                referral.updating = true;
                if (force) {
                    delete referral[referralCode];
                }

                if (!referral || !referralCode) {
                    //no referral at all
                    returnPromise = $q.reject();
                } else if (referral[referralCode]) {
                    //referral info already retrieved
                    returnPromise = $q.resolve(referral[referralCode]);
                } else {
                    returnPromise = Request.make("signup_referral_name", {
                        referral_code: referralCode
                    }).then(function (response) {
                        referral[referralCode] = response.data.response;
                        return referral[referralCode];
                    });
                }
                return returnPromise.finally(function() {
                    referral.updating = false;
                });
            };

            InviteObj.getMessage = function(referral) {
                if (referral.is_affiliate) {
                    return "Claim your invite to join today!";
                } else {
                    return "Claim your invite to join today!";
                }
            };

            InviteObj.updateAliasHistory = function(alias) {
                //this should be moved to a base controller if there's ever any routing done on the front end
                var newParams = $location.search();
                if (alias) {
                    newParams['alias'] = alias;
                }

                var existingParams;
                if ("alias" in newParams) {
                    existingParams = Session.get('paramHistory') || [];
                    newParams['timestamp'] = Math.floor(Date.now() / 1000);
                    existingParams.push(newParams);
                    Session.set('paramHistory', existingParams);
                }
            };

            InviteObj.checkPage = function() {
                var referral = InviteObj.referral;

                if ($location.path().indexOf('/referral/') === 0 && referral.is_affiliate) {
                    $window.location.replace('/a/' + (referral.referralCode || ""));
                    return false;
                }
                if ($location.path().indexOf('/a/') === 0 && !referral.is_affiliate) {
                    $window.location.replace('/referral/' + (referral.referralCode || ""));
                    return false;
                }
                return true;

            };

            /**
             *
             * @param email
             * @param {Object} options
             * @param {bool} options.reset
             * @returns {*}
             */
            InviteObj.submitSignup = function(email, options = {}) {
                let referral = InviteObj.getSavedReferral();
                let history = InviteObj.getParamHistory();

                var data = {
                    email_address: email,
                    referral_code: referral.referralCode,
                    alias: referral.alias,
                    paramHistory: history
                };
                if (typeof options.reset === "boolean") {
                    data.reset = options.reset;
                }

                return Request.make("signup_request", data).then(function (response) {
                    Session.remove('referral');
                    Session.remove('referralName');
                    Session.remove('paramHistory');
                    Track.send('Sign Up Email', 'email', {});

                    if (response.data && response.data.code) {
                        Track.gtm({'event': 'web_signup_request', 'userId': response.data.code});
                    }

                    return response;
                });
            };

            InviteObj.updateUtmTracking = function() {

                let params = $location.search();
                let utmKeys = [
                    "utm_campaign", "utm_medium", "utm_source", "utm_term", "utm_content", "siteID", "ranMID", "ranEAID", "ranSiteID"];
                let utmParams = {};

                utmKeys.forEach(function(item) {
                    if (params[item]) {
                        utmParams[item] = params[item];
                    }
                });
                if (Object.keys(utmParams).length) {
                    Session.set('utmParams', utmParams);
                }
            }

            InviteObj.getAxosEnrollLink = function() {
                let domain = $window.consts.axosEnrollDomain;

                let utmParams = Session.get('utmParams') || {};
                utmParams["products[]"] = "MPO";

                let params = $httpParamSerializer(utmParams);

                return `https://${domain}/?${params}`;


            }

            return InviteObj;


        }];
        return this;

    }])

    // Initialization of module
    .run(['$state', '$location', 'InviteService', function ($state, $location, InviteService) {

        InviteService.updateUtmTracking();
        InviteService.updateAliasHistory();
        InviteService.updateReferral().then(function() {
            if (InviteService.checkPage()) {
                if ($location.path().startsWith('/a/')) {
                    InviteService.updateAliasHistory("affiliate");
                }
            }
        });

    }]);

})();
