//https://github.com/Agezao/confetti-js

/**
 *
 * @example:
        $scope.pageData.confetti = {
            options: {
                target: 'confetti-holder', // Id of the canvas
                max: 60,
                size: 2.5, // prop size
                animate: true, // Should aniamte?
                props: ['circle', 'square', 'triangle'], // Types of confetti
                //purple, blue, yellow, orange, red
                colors: [[131, 106, 148], [75, 169, 214], [255, 206, 85], [254, 183, 109], [255, 105, 102]], // Colors to render confetti
                clock: 25, // Speed of confetti fall
                onResize: function(options) {
                    var container = angular.element('.notification-modal-container');
                    options.height = container.outerHeight();
                    options.width = container.outerWidth();
                }
            },
            state: {
                active: true
            }
        };
 *      <canvas confetti="pageData.confetti" id="confetti-holder"></canvas>
 *
 */
(function() {
    'use strict';
    angular.module('wbUtilities').directive('confetti', ['$state', '$timeout', '$window', function ($state, $timeout, $window) {
        return {
            restrict: 'A',
            scope: {
                confetti: '=',
            },
            link: function (scope, element) {

                scope.confetti || (scope.confetti = {}); // jshint ignore:line
                scope.confetti.state || (scope.confetti.state = {
                    interval: null,
                    active: false
                }); // jshint ignore:line

                scope.confetti.options = angular.extend({
                    target: 'confetti-holder', // Id of the canvas
                    max: 80, // Max itens to render
                    size: 1, // prop size
                    animate: true, // Should aniamte?
                    props: ['circle', 'square', 'triangle', 'line'], // Types of confetti
                    colors: [[165,104,246],[230,61,135],[0,199,228],[253,214,126]], // Colors to render confetti
                    clock: 25, // Speed of confetti fall
                    width: $window.innerWidth, // canvas width (as int, in px)
                    height: $window.innerHeight, // canvas height (as int, in px)
                    onResize: function() {}
                }, scope.confetti.options);

                update();

                scope.$watch("confetti.options", function (options, prev) {
                    if (options) {
                       // update();
                    }
                }, true);

                function makeConfetti(options, state) {
                    //////////////
                    // Properties
                    var cv = angular.element("#"+options.target).get(0);
                    var ctx = cv.getContext("2d");
                    var particles = [];

                    //////////////
                    // Random helper (to minimize typing)
                    function rand(limit, floor) {
                        if(!limit) limit = 1;
                        var rand = Math.random() * limit;
                        return !floor ? rand : Math.floor(rand);
                    }

                    //////////////
                    // Confetti particle generator
                    function particleFactory() {
                        var p = {
                            prop: options.props[rand(options.props.length, true)], //prop type
                            x: rand(options.width), //x-coordinate
                            y: (options.animate?-100:rand(options.height)), //y-coordinate
                            radius: rand(4) + 1, //radius
                            line: Math.floor(rand(65) - 30), // line angle
                            angles: [rand(10, true) + 2, rand(10, true) + 2, rand(10, true) + 2, rand(10, true) + 2], // triangle drawing angles
                            color: options.colors[rand(options.colors.length, true)], // color
                            rotation: rand(360, true) * Math.PI/180,
                            speed: rand(options.clock / 7) + (options.clock / 30)
                        };

                        return p;
                    }

                    //////////////
                    // Confetti drawing on canvas
                    function particleDraw(p) {
                        var op = (p.radius <= 3) ? 0.4 : 0.8;

                        ctx.fillStyle = ctx.strokeStyle = "rgba(" + p.color + ", "+ op +")";
                        ctx.beginPath();

                        switch(p.prop) {
                            case 'circle':{
                                ctx.moveTo(p.x, p.y);
                                ctx.arc(p.x, p.y, p.radius * options.size, 0, Math.PI * 2, true);
                                ctx.fill();
                                break;
                            }
                            case 'triangle': {
                                ctx.moveTo(p.x, p.y);
                                ctx.lineTo(p.x + (p.angles[0] * options.size), p.y + (p.angles[1] * options.size));
                                ctx.lineTo(p.x + (p.angles[2] * options.size), p.y + (p.angles[3] * options.size));
                                ctx.closePath();
                                ctx.fill();
                                break;
                            }
                            case 'line':{
                                ctx.moveTo(p.x, p.y);
                                ctx.lineTo(p.x + (p.line * options.size), p.y + (p.radius * 5));
                                ctx.lineWidth = 2 * options.size;
                                ctx.stroke();
                                break;
                            }
                            case 'square': {
                                ctx.save();
                                ctx.translate(p.x+15, p.y+5);
                                ctx.rotate(p.rotation);
                                ctx.fillRect(-15 * options.size,-5 * options.size,15 * options.size,5 * options.size);
                                ctx.restore();
                                break;
                            }
                        }
                    }

                    //////////////
                    // Public itens
                    //////////////

                    //////////////
                    // Clean actual state
                    var _clear = function() {
                        ctx.clearRect(0, 0, cv.width, cv.height);
                        cv.width = null;
                        cv.height = null;
                        clearInterval(state.interval);
                        state.active = false;
                    };

                    //////////////
                    // Render confetti on canvas
                    var _render = function() {
                        //canvas dimensions
                        cv.width = options.width;
                        cv.height = options.height;
                        particles = [];

                        for(var i = 0; i < options.max; i ++) {
                            particles.push(particleFactory());
                        }

                        function draw(){
                            ctx.clearRect(0, 0, cv.width, cv.height);

                            cv.width = options.width;
                            cv.height = options.height;

                            // ctx.clearRect(0, 0, options.width, options.height);
                            if (options.max > particles.length) {
                                particles.push(particleFactory());

                            }

                            for (var i = 0; i < options.max; i++) {
                                var p = particles[i];
                                if (!p) {
                                    particles.push(particleFactory());
                                }

                                particleDraw(particles[i]);
                            }

                            update();
                        }

                        function update() {
                            for (var i = 0; i < particles.length; i++) {
                                var p = particles[i];

                                if (!p) { return; }

                                if(options.animate) {
                                    p.y += p.speed;
                                }

                                if (p.y > options.height) {
                                    particles[i] = p;
                                    particles[i].x = rand(options.width, true);
                                    particles[i].y = -10;
                                }
                            }
                        }

                        state.active = true;

                        //animation loop
                        if(options.animate) {
                            state.interval = setInterval(draw, 20);
                            return state.interval;
                        } else {
                            return draw();
                        }
                    };

                    return {
                        render: _render,
                        clear: _clear
                    };
                }

                function update() {
                    var obj;

                    scope.confetti.options.onResize(scope.confetti.options);

                    if (scope.confetti.clear) {
                        scope.confetti.clear();
                    }
                    obj = makeConfetti(scope.confetti.options, scope.confetti.state);
                    setObj(obj);

                    if (scope.confetti.state.active) {
                        scope.confetti.start();
                    }
                }

                function setObj(obj) {
                    scope.confetti = angular.extend(scope.confetti, {
                        start: function() {
                            scope.confetti.options.onResize(scope.confetti.options);
                            obj.clear();
                            obj.render();
                            var goalHeight = scope.confetti.options.height;
                            if (scope.confetti.options.animate) {
                                element.height = 0;
                            }
                        },
                        stop: function() {
                            obj.clear();
                        },
                        active: function() {
                            return scope.confetti.state.active;
                        }
                    });
                }

                function resize() {
                    if (scope.confetti.active()) {
                        update();
                    }
                }
                angular.element($window).on('resize', resize);

                scope.$on('$destroy', function () {
                    angular.element($window).off('resize', resize);
                });
            }
        };
    }]);
})();
