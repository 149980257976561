(function() {
    'use strict';
    angular.module('wbPublic').controller('InvitedPageController',
        ['$scope', '$window', '$location', '$timeout', '$q', 'Request', 'Session',
    function($scope, $window, $location, $timeout, $q, Request, Session) {
        /*jshint -W069 */

        $scope.pageData = {
            showEmailConfirmed: false,
            showSignupLink: false,
            email: null,
            code: null,
        };

        $scope.links = {};
        $scope.inviteData = {};
        $scope.timers = {};


        $scope.pageData.code = $location.path().split('/').filter(function(v) {return v;}).pop();
        $scope.emailToken  = $location.search()['token'];


        if ($scope.emailToken) {
            verifyEmail();
        } else {
            verifyInviteCode();
        }

        $scope.$on('pageBecameHidden', function() {
            $timeout.cancel($scope.timers.webkit);
            $timeout.cancel($scope.timers.iframe);
        });

        $scope.goDashboard = function() {
            $window.location.href = "/dashboard";
        };

        function verifyEmail() {
            Request.make('signup_verify_email', {code: $scope.pageData.code, verification_code:$scope.emailToken}).then(function(response) {
                if (response.data.status === 100) {
                    $scope.pageData.email = response.data.email_address;
                    forwardToSignup();
                } else {
                    $scope.pageData.showEmailConfirmed = true;
                    //go login
                }
            }).catch(function() {
                verifyInviteCode();
            });
        }

        function verifyInviteCode() {
            Request.make('signup_init', {code:$scope.pageData.code}).then(function(response) {
                $scope.pageData.email = response.data.email_address;

                forwardToSignup();

            }).catch(function(err) {
                var errorMsg = "Sorry, there was an issue with your invite link.";
                if ("data" in err && err.data.message) {
                    errorMsg = err.data.message;
                }
                var message = {
                    "type": "warning",
                    "text": errorMsg
                };
                Session.set("message", message);
                $window.location.replace("/managed-portfolios");

            });
        }


        function forwardToSignup() {
            $scope.links = {
                custom: `wbsignuplink://openapp?invite_code=${$scope.pageData.code}&email=${$scope.pageData.email}`,
                alt: `http://wbsignuplink.com?invite_code=${$scope.pageData.code}&email=${$scope.pageData.email}`,
                g_intent: `intent://openapp?invite_code=${$scope.pageData.code}&email=${$scope.pageData.email}/#Intent;scheme=wbsignuplink;package=com.wisebanyan.android;end`,
                ios: `wbSignupLink://openApp?invite_code=${$scope.pageData.code}&email=${$scope.pageData.email}`,
                signup: `/signup/${$scope.pageData.code}`
            };
            redirectIfMobileOrNot();

            $timeout(function () {
                $scope.pageData.showSignupLink = true;
            }, 1500);

        }


        $scope.getStarted = redirectIfMobileOrNot;
        function redirectIfMobileOrNot() {
            if (redirectIfMobileOrNot.triggered) {
                //on second attempt will just go to web signup
                $window.location.href = $scope.links.signup;
            } else if (isIOS()) {
                redirectIOS();
            } else if (isAndroid()) {
                redirectAndroid();
            } else {
                $window.location.href = $scope.links.signup;
            }
            redirectIfMobileOrNot.triggered = true;
        }
        function redirectIOS() {
            $timeout(function () {
                $window.location.href = $scope.links.ios;
            }, 25);
        }
        function redirectAndroid() {
            if ($window.navigator.userAgent.match(/Chrome/)) {
                $window.location.href = $scope.links.g_intent;
            } else if ($window.navigator.userAgent.match(/Firefox/)) {
                document.location = $scope.links.custom;
                $scope.timers.webKit = $timeout(function () {
                    document.location = $scope.links.alt;
                }, 1500);
                $scope.timers.iframe = $timeout(function () {
                    createIFrameRedirect();
                }, 500);
            } else {
                createIFrameRedirect();
            }
        }
        function createIFrameRedirect() {
            var iFrame = document.createElement("iframe");
            iFrame.style.border = "none";
            iFrame.style.width = "1px";
            iFrame.style.height = "1px";
            iFrame.onload = function () {
                document.location = $scope.links.alt;
            };
            iFrame.src = $scope.links.custom;
            $window.document.body.appendChild(iFrame);
        }

        function isIOS() {
            return /iPhone|iPad|iPod/i.test($window.navigator.userAgent) && !window.MSStream;
        }
        function isAndroid() {
            return /Android/i.test($window.navigator.userAgent);
        }

    }]);
})();
