(function() {
    'use strict';
    angular.module('wbUtilities').factory('Message',
        ['$sce', '$timeout', '$filter',
        function ($sce, $timeout, $filter) {
        var message = {},
            defaultMessage = {
                key: null,
                title: null,
                text: null,
                type: "info",
                dismissible: true,
                timeout: 5
            },
            messages = [];

        message.set = function () {
            message.clearAll();
            message.add.apply(this, arguments);
        };

        message.add = function(data) {
            //backwards compatibility of previous parameter based set method
            if (typeof data !== "object") {
                var newData = {};
                /* jshint expr: true */
                arguments[0] && (newData.text = arguments[0]);
                arguments[1] && (newData.type = arguments[1]);
                arguments[2] && (newData.title = arguments[2]);
                arguments[3] && (newData.dismissible = arguments[3]);
                data = newData;
            }

            data = angular.extend({}, defaultMessage, data);

            data.text = $sce.trustAsHtml(data.text);

            if (data.key) { //only clear if key isn't undefined or null
                message.clear(data.key);
            }

            if (data.timeout !== false) {
                $timeout(function () {
                    if (data.key) {
                        message.clear(data.key);
                    } else {
                        message.clearLast();
                    }
                }, data.timeout * 1000);
            }

            messages.push(data);
        };

        message.clearAll = function() {
            messages.length = 0;
        };

        message.clearLast = function() {
            return messages.pop();
        };

        message.get = function () {
            return messages;
        };

        /**
         * Clears certain messages
         * key == undefined - clear all messages with no key set, eg key = null
         * key is number - remove that index
         * key is string - remove any matching keys
         * @param key
         */
        message.clear = function (key) {
            if (typeof key === "undefined") key = null;

            if (typeof key === "number") {
                messages.splice(key, 1);
            } else {
                var index = messages.length - 1;
                while (index >= 0) {
                    //need to iterate in reverse in case of duplicates and changing indexes
                    if ("key" in messages[index] && messages[index].key == key) {
                        messages.splice(index, 1);
                    }
                    index--;
                }
            }
        };

        message.count = function () {
            return messages.length;
        };

        /**
         * @returns {boolean}
         */
        message.hasMessage = function (key) {
            if (typeof key == "undefined") {
                return !!message.count();
            } else {
                var found = $filter('filter')(message, {key: key}, true);
                return !!found.length;
            }
        };

        return message;

    }]);

})();
