(function() {
    'use strict';
    angular.module('wbUtilities').factory('Milestone', ['$q', '$filter', 'Request', 'Misc', function ($q, $filter, Request, Misc) {
        var msUtil = {
            signup: false,
            ms: {}, //stores actual milestone to submit
            msDefault: {
                accounts: [{auto_deposit: null}],
                auto_deposit: {},
                allocation: {},
                metadata: {},
                metrics: {},
                extra: {defaultsSet: false}
            },
            data: {
                extraDefaults: { defaultsSet: false },
                availableDefaults: {},
                autoDeposit: {},
                retirement: {},
                frequencies : Misc.deposit_frequencies,
                iraAccounts: [],
                glideFutures: {}
            },
        };


        msUtil.options = {
            allocationStrategies: {
                "cash_first": "Cash First",
                "divide_deposits": "Divide Deposits",
            }
        };

        msUtil.validation = {
            period: {
                min: "1 min",
                max: "99 max",
                number: "Bad number",
                required: "Required"
            }
        };
        msUtil.validation.period_rainy_day = angular.extend({}, msUtil.validation.period, {max: "48 max"});


        /**
         * Remote requests
         */
        (function setRequests() {
            msUtil.getAvailable = function (data) {
                var routeKey = "milestone_available";

                return Request.make(routeKey, data).then(function (response) {
                    var availableRaw = response.data;
                    var availableData = {};
                    angular.forEach(availableRaw.available_milestones, function (ms) {
                        angular.forEach(ms.accounts, function(account) {
                            account.name = account.type.replace("Investment ", "");
                            account.type = account.type.toLowerCase().replace(/[\(\)]/g, "").replace(/ /g,"-");
                        });
                        availableData[ms.type] = ms;
                    });
                    msUtil.data.availableDefaults = availableData;
                    msUtil.data.income = availableRaw.income;

                    if (availableData.retirement) {
                        msUtil.data.retirement.accountsAvailable = availableData.retirement.accounts;
                        msUtil.data.retirement.contributionYears = availableData.retirement.to_contribution_years;
                    }


                    return availableData;
                });
            };

            /**
             * @deprecated signup only
             * @param ms
             * @returns {*}
             */
            msUtil.getCashRecommend = function (ms) {

                var data = {
                    milestone_type: ms.type,
                    target_amount: ms.target_amount,
                };
                if (msUtil.signup) {
                    Object.assign(data, {
                        income: msUtil.data.income,
                        birth_date: msUtil.data.birth_date,
                    });
                }

                if (ms.type === 'retirement') {
                    data.retirement_age = ms.metadata.retirement_age;
                } else {
                    data.target_date = ms.metadata.target_date
                }

                return Request.make("milestone_cash_recommend", data).then(function(response) {
                    var cashAllocation = response.data.response.cash_allocation;

                    cashAllocation = Math.round(cashAllocation*100)/100;

                    if (!ms.allocation.cash || ms.allocation.cash === ms.extra.cash_recommended_allocation) {
                        //if it's not set, or it already was the previously recommended amount
                        ms.extra.cash_percent = Math.round(cashAllocation * 100); //round to prevent 0.07*100=7.000000000000001
                        ms.allocation.cash = cashAllocation;
                    }

                    ms.extra.cash_recommended_allocation = cashAllocation;

                    return cashAllocation;
                });

            };

            /**
             * @deprecated signup only
             * @param ms
             * @returns {*}
             */
            msUtil.getAllocationRecommend = function (ms) {

                if (ms.type === "cash") {
                    return $q.when(true).then(function () {
                        if (!ms.id) {
                            ms.extra.allocation_value = 0;
                        }
                        return {allocation_model: "0.0"};
                    })
                }

                var months = null;
                if (ms.type === "retirement") {
                    //months should be null, do nothing, will be removed from request since null
                } else if (ms.type === "build_wealth" || ms.type_account) {
                    months = 0; //special case for these types
                } else {
                    months = ms.metadata.months_to_goal || 0; //all other milestones
                }

                var data = {
                    months_to_goal: months,
                    retirement_age: ms.metadata.retirement_age,
                };

                if (msUtil.signup) {
                    angular.extend(data, {
                        birth_date: msUtil.data.birth_date,
                        risk_score: msUtil.data.risk_score
                    })
                }

                return $q.when().then(function() {
                    return msUtil.retrieveAllocationScore(data);
                }).then(function (response) {

                    var score = response.allocation_score;

                    //if it hasn't been set,
                    //or
                    //on create the milestone was already set as recommended, but recommended changed
                    if ( ms.extra.allocation_value == null || isNaN(ms.extra.allocation_value)
                        ||
                          (
                              //a new milestone and still set to last recommended
                              !ms.id
                            && ms.extra.allocation_value !== 0
                            && ms.extra.allocation_value === ms.allocation.recommended*1
                          )
                    ) {
                        ms.extra.allocation_value = score*1;
                    }

                    ms.allocation.recommended = score;

                    return data;
                });
            };
            msUtil.getGlideRecommend = function (ms) {

                var data = {
                    milestone_type: ms.type_real,
                    life_expectancy: ms.metadata.life_expectancy,
                };

                return msUtil.retrieveGlideRecommend(data).then(function (glideRecommend) {

                    ms.allocation.recommendation || (ms.allocation.recommendation = {});

                    //set allocation
                    if (  !ms.allocation.allocation_start
                        ||
                          (
                              !ms.id
                            && ms.extra.allocation_start_value !== 0
                            && ms.extra.allocation_start_value === ms.allocation.recommendation.allocation_start*1
                          )
                    ) {
                        //if it's not set, or it already was the previously recommended amount
                        ms.extra.allocation_start_value = glideRecommend.allocation_start*1;
                        ms.allocation.allocation_start = glideRecommend.allocation_start;
                    }
                    
                    if (  !ms.allocation.allocation_end
                        ||
                          (
                              !ms.id
                            && ms.extra.allocation_end_value !== 0
                            && ms.extra.allocation_end_value === ms.allocation.recommendation.allocation_end*1
                          )
                    ) {
                        ms.extra.allocation_end_value = glideRecommend.allocation_end*1;
                        ms.allocation.allocation_end = glideRecommend.allocation_end;
                    }


                    //set cash
                    if (  (!ms.allocation.cash_start && ms.allocation.cash_start !== 0)
                        ||
                          ( !ms.id && ms.allocation.cash_start === ms.allocation.recommendation.cash_start )
                    ) {
                        //if it's not set, or it already was the previously recommended amount
                        ms.extra.cash_start_percent = Math.round(glideRecommend.cash_start*100); //round to prevent 0.07*100=7.000000000000001
                        ms.allocation.cash_start = glideRecommend.cash_start;
                    }

                    if ( (!ms.allocation.cash_end && ms.allocation.cash_end !== 0)
                        ||
                          ( !ms.id && ms.allocation.cash_end === ms.allocation.recommendation.cash_end )
                    ) {
                        ms.extra.cash_end_percent = Math.round(glideRecommend.cash_end*100);
                        ms.allocation.cash_end = glideRecommend.cash_end;
                    }


                    ms.allocation.recommendation = glideRecommend;

                    return glideRecommend;
                });
            };

            /**
             * @deprecated signup only
             * @param {Object} data
             * @param {int} data.months_to_goal
             * @param {int} data.retirement_age
             *
             * //signup only
             * @param {date} [data.birth_date]
             * @param {int} [data.risk_score]
             * @returns {*}
             */
            msUtil.retrieveAllocationScore = function(data) {
                data = angular.copy(data);
                if (data.retirement_age && data.months_to_goal) {
                    data.months_to_goal = null;
                }
                return Request.make("milestone_allocation_recommend", data).then(function(response) {
                    return response.data.response;
                });
            };
            /**
             *
             * @param {Object} data
             * @param {string} data.milestone_type
             * @param {number} data.life_expectancy
             * @returns {*}
             */
            msUtil.retrieveGlideRecommend = function(data) {
                data = angular.copy(data);
                if (!data.milestone_type.includes('retirement')) {
                    data.life_expectancy = null;
                }
                return Request.make("milestone_glide_recommend", data).then(function(response) {
                    var glide = response.data;

                    delete glide.allocation_recommendations;

                    if (glide.cash_start) glide.cash_start *= 1;
                    if (glide.cash_end) glide.cash_end *= 1;

                    if (!glide.cash_end && glide.cash_end !== 0) {
                        glide.allocation_end = glide.allocation_start;
                        glide.cash_end = glide.cash_start;
                        //personal wealth has no end
                    }

                    return glide;
                });
            };

            msUtil.getGlidePortfolioTypeRecommend = function () {

                var data = {
                    milestone_type: msUtil.ms.type_real,
                };
                switch (data.milestone_type) {
                    case 'retirement':
                    case 'retirement_shared':
                        data.life_expectancy = msUtil.ms.metadata.life_expectancy;
                        break;
                    case 'rainy_day':
                        data.target_amount = msUtil.ms.target_amount;
                        data.recommended_target_amount = msUtil.ms.target_amount;
                        break;
                    case 'custom':
                    case 'new_home':
                    case 'new_car':
                        data.target_date = msUtil.ms.metadata.target_date;
                        break;
                    default:
                        // msUtil.ms.extra.portfolioTypeRecommend = {};
                        // return $q.when(msUtil.ms.extra.portfolioTypeRecommend);
                }

                return msUtil.retrieveGlidePortfolioTypeRecommend(data).then(function (rec) {

                    msUtil.ms.extra.portfolioTypeRecommend = rec;

                    return rec;
                });
            };
            msUtil.retrieveGlidePortfolioTypeRecommend = function(data) {
                data = angular.copy(data);
                return Request.make("milestone_glide_portfolio_recommend", data).then(function(response) {
                    return response.data;
                });
            };

            msUtil.retrieveGlideFuture = function() {
                var data = {
                    allocation_start: msUtil.ms.allocation.allocation_start,
                    allocation_end: msUtil.ms.allocation.allocation_end,
                    cash_start: msUtil.ms.allocation.cash_start,
                    cash_end: msUtil.ms.allocation.cash_end,
                    milestone_type: msUtil.ms.type
                };

                if (msUtil.ms.extra.portfolio_slug && msUtil.ms.extra.portfolio_slug !== "core") {
                    data.allocation_start = msUtil.ms.extra.portfolio_slug;
                    data.allocation_end = msUtil.ms.extra.portfolio_slug;
                }

                var key = data.milestone_type + data.allocation_start + data.allocation_end + data.cash_start + data.cash_end;

                msUtil.ms.extra.glideFuture = [];

                $q.when(1).then(function() {
                    if (msUtil.data.glideFutures[key]) {
                        msUtil.ms.extra.glideFuture = msUtil.data.glideFutures[key];
                        return msUtil.ms.extra.glideFuture;
                    } else {
                        Request.make("milestone_glide_future", data).then(function(response) {
                            var future = response.data.glide_allocations;

                            msUtil.data.glideFutures[key] = future;
                            msUtil.ms.extra.glideFuture = msUtil.data.glideFutures[key];
                            return msUtil.ms.extra.glideFuture;
                        });
                    }
                });
            };


            msUtil.create = function (data) {
                return Request.make("milestone_create", data).then(function (response) {
                    return response.data.response;
                });
            };

            msUtil.update = function (data) {
                return Request.make("milestone_update", data);
            };

            msUtil.available_accounts = function (data) {
                return Request.make("milestone_available_accounts", data).then(function (response) {
                    var accounts = response.data.response.accounts;

                    angular.forEach(accounts, function(account) {
                        account.name = account.type.replace("Investment ", "");
                        account.type = account.type.toLowerCase().replace(/[\(\)]/g, "").replace(/ /g,"-");
                    });

                    return accounts;
                });
            };

            msUtil.add_account = function (data) {
                return Request.make("milestone_add_account", data);
            };

            msUtil.delete = function (data) {
                return Request.make("milestone_delete", data);
            };

            /**
             * @param {Object} data
             * @param {Number} data.amount
             * @returns {*}
             */
            msUtil.updateInitialDeposit = function (data) {
                return Request.make("update_initial_deposit", data);
            };


            msUtil.getNewDefaultAutoDeposit = function() {
                var requestData = getGlideAutoData();
                return Request.make("milestone_new_auto_recommend", requestData).then(function (response) {
                    var data = response.data;

                    msUtil.ms.auto_deposit.recommended = data.amount;
                    if (!msUtil.ms.id && msUtil.ms.auto_deposit.amount == null && data.amount !== null) {
                        msUtil.ms.auto_deposit.amount = data.amount;
                    }

                    //might as well update the target_time & target_age here
                    var target = msUtil.ms.metadata.target_date;
                    msUtil.ms.extra.target_time = target ? msUtil.getNiceDuration(moment(target)) : null;
                    msUtil.ms.extra.target_age = target ? Math.round(moment(moment(target)).diff(moment(msUtil.data.birth_date), "years", true) * 10)/10 : null;
                    if (msUtil.ms.type === 'retirement') {
                        msUtil.ms.extra.target_age = msUtil.ms.metadata.retirement_age;
                    }

                    return data;
                });
            };
            msUtil.getNewEstimatedDate = function() {
                var requestData = getGlideAutoData();
                return Request.make("milestone_estimated_achievement", requestData).then(function (response) {
                    var data = response.data;
                    var estimated_achievement = data.estimated_date || data.estimated_achievement;

                    data.est_time = estimated_achievement ? msUtil.getNiceDuration(moment(estimated_achievement)) : null;
                    data.est_age = estimated_achievement ? Math.round(moment(moment(estimated_achievement)).diff(moment(msUtil.data.birth_date), "years", true) * 10)/10 : null;
                    // data.target_time = data.target_date ? msUtil.getNiceDuration(moment(data.target_date)) : null;
                    // data.target_age = data.target_date ? Math.round(moment(moment(data.target_date)).diff(moment(msUtil.data.birth_date), "years", true) * 10)/10 : null;

                    //set the value if it's never been set before

                    //update the date
                    msUtil.ms.extra.est_time = data.est_time;
                    msUtil.ms.metrics.est_age = data.est_age;
                    msUtil.ms.metrics.estimated_achievement = estimated_achievement;

                    return data;
                });
            };
            function getGlideAutoData() {
                var data = {
                    auto_deposit: msUtil.ms.auto_deposit.amount,

                    msType: msUtil.ms.type_real || msUtil.ms.type, //needed for url param itself
                    milestone_id: msUtil.ms.id || null,

                    target_amount: msUtil.ms.target_amount,
                    one_time_deposit: msUtil.ms.initial_deposit,
                    
                    allocation_start: msUtil.ms.allocation.allocation_start,
                    allocation_end: msUtil.ms.allocation.allocation_end,
                    cash_start: msUtil.ms.allocation.cash_start,
                    cash_end: msUtil.ms.allocation.cash_end,

                    //rainy_day
                    months_to_goal: msUtil.ms.metadata.months_to_goal,

                    //all but build wealth
                    target_date: msUtil.ms.metadata.target_date,

                    //retirement
                    retirement_age: msUtil.ms.metadata.retirement_age,
                    spend_per_year: msUtil.ms.metadata.spend_per_year,
                    life_expectancy: msUtil.ms.metadata.life_expectancy,
                    social_security_age: msUtil.ms.metadata.social_security_age,
                    social_security_payout: msUtil.ms.metadata.social_security_payout,

                    start_date: moment().format('YYYY-MM-DD')
                };

                if (msUtil.ms.extra.portfolio_slug && msUtil.ms.extra.portfolio_slug !== "core") {
                    data.allocation_start = msUtil.ms.extra.portfolio_slug;
                    data.allocation_end = msUtil.ms.extra.portfolio_slug;
                }

                return data;
            }

            /**
             * @deprecated signup only
             * @returns {*}
             */
            msUtil.getEstimatedDate = function() {
                var requestData = getAutoDepositRecommendData(msUtil.ms.extra.auto_deposit_set ? msUtil.ms.auto_deposit.amount : null);
                if (msUtil.ms.extra.auto_deposit_set && msUtil.ms.auto_deposit.amount > 50000) {
                    return $q.reject({"error":"Auto-Deposit over $50K"});
                }
                return Request.make("milestone_auto_recommend", requestData).then(function (response) {
                    var data = response.data.response;

                    //generate estimated and target time(months&years)/age
                    data.est_time = data.est_achievement_date ? msUtil.getNiceDuration(moment(data.est_achievement_date)) : null;
                    data.est_age = data.est_achievement_date ? Math.round(moment(moment(data.est_achievement_date)).diff(moment(msUtil.data.birth_date), "years", true) * 10)/10 : null;
                    data.target_time = data.target_date ? msUtil.getNiceDuration(moment(data.target_date)) : null;
                    data.target_age = data.target_date ? Math.round(moment(moment(data.target_date)).diff(moment(msUtil.data.birth_date), "years", true) * 10)/10 : null;

                    //set the value if it's never been set before
                    if (!msUtil.ms.id && msUtil.ms.auto_deposit.amount == null) {
                        msUtil.ms.auto_deposit.amount = data.auto_deposit * 1;
                        msUtil.ms.auto_deposit.frequency = data.auto_deposit_frequency.toLowerCase();
                    }

                    //update the date
                    msUtil.ms.target_amount = data.target_amount;
                    msUtil.ms.metadata.target_date = data.target_date;

                    msUtil.ms.extra.est_time = data.est_time;
                    msUtil.ms.extra.target_time = data.target_time;

                    msUtil.ms.metrics.est_age = data.est_age;
                    msUtil.ms.metrics.target_age = data.target_age;
                    msUtil.ms.metrics.estimated_achievement = data.est_achievement_date;

                    return data;
                });
            };
            /**
             * @deprecated signup only
             * @returns {*}
             */
            msUtil.getDefaultAutoDeposit = function() {
                var requestData = getAutoDepositRecommendData(null);
                return Request.make("milestone_auto_recommend", requestData).then(function (response) {
                    var data = response.data.response;
                    msUtil.ms.auto_deposit.recommended = data.auto_deposit * 1;
                    msUtil.ms.extra.auto_deposit_frequency_recommended = data.auto_deposit_frequency.toLowerCase();
                    msUtil.data.extraDefaults.auto_deposit_frequency_recommended = msUtil.ms.extra.auto_deposit_frequency_recommended;
                    return data;
                });
            };

            /**
             * @deprecated signup only
             * If amount isn't set, it will return the default deposit amount
             * Otherwise it will return the date for the submitted amount
             * @param amount
             * @returns {{msType: string|*, milestone_type: string|*, target_amount: *|null, one_time_deposit: number|*, allocation_model: string|*, cash_allocation: *|null|number, months_to_goal: *, target_date: *, retirement_age: *|Document.retirement_age|int, spend_per_year: *, life_expectancy: *, social_security_age: *, social_security_payout: *, risk_score: *|int, auto_deposit_frequency: null|*, income: *|Document.income, birth_date: *}}
             */
            function getAutoDepositRecommendData(amount) {
                var data = {
                    msType: msUtil.ms.type_real || msUtil.ms.type, //needed for url param itself
                    milestone_id: msUtil.ms.id || null,

                    target_amount: msUtil.ms.target_amount,
                    one_time_deposit: msUtil.ms.initial_deposit,
                    allocation_score: msUtil.ms.allocation.allocation_model || msUtil.ms.allocation.allocation_start,
                    cash_allocation: msUtil.ms.allocation.cash || msUtil.ms.allocation.cash_start,

                    //rainy_day
                    months_to_goal: msUtil.ms.metadata.months_to_goal,

                    //all but build wealth
                    target_date: msUtil.ms.metadata.target_date,

                    //retirement
                    retirement_age: msUtil.ms.metadata.retirement_age,
                    spend_per_year: msUtil.ms.metadata.spend_per_year,
                    life_expectancy: msUtil.ms.metadata.life_expectancy,
                    social_security_age: msUtil.ms.metadata.social_security_age,
                    social_security_payout: msUtil.ms.metadata.social_security_payout,
                };
                data.auto_deposit = amount;

                if (msUtil.signup) {
                    angular.extend(data, {
                        risk_score: msUtil.data.risk_score,
                        auto_deposit_frequency: msUtil.ms.auto_deposit.frequency,
                        income: msUtil.data.income,
                        birth_date: msUtil.data.birth_date
                    })
                }
                return data;
            }


            msUtil.deposit = function (data) {
                return Request.make("milestone_deposit", data);
            };

            msUtil.withdraw = function (data) {
                return Request.make("milestone_withdraw", data);
            };

            msUtil.transfer = function (data) {
                return Request.make("milestone_transfer", data);
            };

            msUtil.iraAssist = function (data) {
                return Request.make("account_ira_assist", data).then(function (response) {
                    return response.data.response;
                });
            };
        })();


        /**
         * Static Data
         */
        msUtil.views = {
            "rainy_day": {
                "name": "Rainy Day",
                "type": "rainy_day",
                "tag": "Cover Your Back",
                "blurb": "Build an emergency fund so you can weather any storm. We recommend this for every client, because life happens."
            },
            "cash": {
                "name": "Save Cash",
                "type": "cash",
                "tag": "Cash Is King",
                "blurb": "Whether you're saving for a purchase, a short-term goal, or a payment, use this milestone to start putting money away. It'll always stay in cash."
            },
            "retirement": {
                "name": "Retirement",
                "type": "retirement",
                "tag" : "In It To Win It",
                "blurb": "Start on the path to financial freedom. It may seem far away, but starting sooner makes it easier to get the retirement lifestyle you want."
            },
            "new_home": {
                "name": "New Home",
                "type": "new_home",
                "tag": "Movin' On Up",
                "blurb": "Whether you're saving toward your first home or new beachside villa, use this milestone to put that roof over your head."
            },
            "new_car": {
                "name": "New Vehicle",
                "type": "new_car",
                "tag": "Vroom vroom",
                "blurb": "This milestone will get you (literally) on the road toward your first set of wheels or upgrading that old duster."
            },
            "custom": {
                "name": "Custom",
                "type": "custom",
                "tag" : "Dream Big",
                "blurb": "Some clients can't be confined to our recommended milestones. Get crazy, get creative, and get what you've always wanted in your life."
            },
            "build_wealth": {
                "name": "Personal Wealth",
                "type": "build_wealth",
                "tag" : "Keep Investing, Meow",
                "blurb": "This milestone is a great way to keep putting money aside after your other milestones are up and running."
            },
            "loading": {
                "name": "Loading",
                "type": "loading",
                "tag" : "Loading please wait",
                "blurb": "Your milestone data will load shortly"
            }
        };

        msUtil.loadingMilestone = {
            "id": "loading",
            "name": "Loading",
            "display_name": "Loading",
            "type": "loading",
            "accounts": [],
            "auto_deposit": {
                "amount": 0,
                "frequency": null,
                "enabled": false
            },
            "metadata": {},
            "metrics": {
                "market_value": 0,
                "total_deposits": 0,
                "total_withdrawals": 0,
                "net_contributions": 0,
                "dividends": 0,
                "earnings": 0,
                "market_changes": 0,
                "market_return": 0
            },
            "allocation": {
                "allocation_model": "5.5",
                "cash": 0,
                "stock_percent": 0,
                "bond_percent": 0,
            },
            "status": 200,
            "extra": {}
        };


        /**
         * Utility Functions
         */

        msUtil.getNiceDuration = function(date) {
            var duration, nice, years, months, days;
            duration = moment(date).diff(new Date());
            duration = moment.duration(duration);
            nice = [];

            years = duration.years();
            months = duration.months();
            days = duration.days();
            if (days > 20) {
                //round short days up
                months++;
                days = 0;
            }
            if (months > 11) {
                months = 0;
                years++;
            }
            if (years === 2 && months === 0) {
                months = 24;
                years = 0;
            }
            if (years === 1) {
                months += 12;
                years = 0;
            }

            if (!days && !years && !months || (days<0 || years<0 || months<0)) {
                nice.push("No Time");
            } else {
                if (years) {
                    nice.push(years + (Math.abs(years) === 1 ? " year" : " years"));
                }
                if (months && years <= 10) {
                    nice.push(months + (Math.abs(months) === 1 ? " month" : " months"));
                }
                if (days && !years && !months) {
                    nice.push(days + (Math.abs(days) === 1 ? " day" : " days"));
                }
            }

            nice = nice.map(function(value) {
                //&nbsp; = \xa0
                return value.replace(" ", "\xa0");
            });
            return nice.join(", ");
        };

        //validation for one time deposit
        msUtil.oneTimeMinValue = function(accountType) {
            if (!accountType) {
                accountType = msUtil.ms.accounts[0].type;
            }
            if (msUtil.ms.extra.min_initial_deposit) {
                return msUtil.ms.extra.min_initial_deposit;
            }

            return accountType.includes('ira')? 0 : 1;
        };

        /**
         * This takes a milestone object as returned from the milestone endpoint and sets
         * the milestone data for what's needed for create/update form
         *
         * @param msObj
         */
        msUtil.setMilestoneUpdateFromObject = function(msObj) {

            var ms = angular.copy(msObj);

            //keys from milestone object that are unnecessary for the submit object
            delete ms.allocation.allocation_slug;
            delete ms.deletable;
            delete ms.deleted_at;
            delete ms.deleted_transfer_to;
            delete ms.has_glide_path;
            delete ms.is_shared;
            delete ms.status;
            delete ms.milestone_group_id;
            //shouldn't exist in the future
            delete ms.shared_metrics;
            delete ms.partners;
            delete ms.share_status;

            if (msObj.type_account) {
                ms.type = 'other';
                ms.type_real = 'other';
            }

            ms.initial_deposit = (msObj.metrics.value ? msObj.metrics.value : 1);

            ms.extra = {
                period: msObj.metadata.target_date ? Math.round(moment(msObj.metadata.target_date).diff(new Date(), "months", true)) : null,
                period_months: msObj.metadata.target_date ? (Math.round(moment(msObj.metadata.target_date).diff(new Date(), "months", true)) % 12) : null,
                period_years: msObj.metadata.target_date ? Math.floor(Math.round(moment(msObj.metadata.target_date).diff(new Date(), "months", true)) / 12) : null,
                period_unit: "Months",

                auto_deposit_original: msObj.auto_deposit.amount || 0,

                annual_deposit_total: 0,
                est_time: msObj.metrics.estimated_achievement ? msUtil.getNiceDuration(moment(msObj.metrics.estimated_achievement)) : null,
                target_time: msObj.metadata.target_date ? msUtil.getNiceDuration(moment(msObj.metadata.target_date)) : null,

                portfolio_type: (msObj.allocation.allocation_model === "0.0") ? "cash" : (msObj.allocation.cash_start?"stocks_bonds_cash":"stocks_bonds"),
                portfolio_slug: msObj.allocation.allocation_slug || "core",
                allocation_value: (!msObj.allocation.allocation_slug) ? msObj.allocation.allocation_model*1 : null,
                allocation_start_value: (!msObj.allocation.allocation_slug) ? msObj.allocation.allocation_start*1 : null,
                allocation_end_value: (!msObj.allocation.allocation_slug) ? msObj.allocation.allocation_end*1 : null,
                cash_percent: (msObj.allocation.allocation_model === "0.0")
                                    ?  100
                                    : ((msObj.allocation.cash || msObj.allocation.cash===0)
                                            ? Math.round(msObj.allocation.cash*100)
                                            : null
                                    ),
                cash_start_percent: Math.round(msObj.allocation.cash_start*100),
                cash_end_percent: Math.round(msObj.allocation.cash_end*100),
                glideRecommend: {start:{}, end:{}},

                //if retirement
                life_expectancy_initial: msObj.metadata.life_expectancy,
                social_security_percent: msObj.metadata.social_security_payout * 100
            };

            if (ms.allocation.allocation_start.length > 4) {
                ms.extra.portfolio_simple_type = "portfolio_custom";
            } else if (ms.allocation.allocation_start === ms.allocation.allocation_end && !ms.allocation.cash_start && !ms.allocation.cash_end) {
                ms.extra.portfolio_simple_type = "portfolio_basic";
            } else {
                ms.extra.portfolio_simple_type = "portfolio_glide";
            }


            if ((ms.extra.period || ms.extra.period === 0) && ms.extra.period < 1) {
                ms.extra.period = 0;
                ms.extra.period_years = 0;
                ms.extra.period_months = 1;
            }

            angular.forEach(msUtil.data.extraDefaults, function(val, key) {
                if (!(key in ms.extra)) {
                    ms.extra[key] = val;
                }
            });

            angular.forEach(ms.accounts, function(account) {
                if (account.annual_deposit_limit) {
                    ms.extra.annual_deposit_total += account.annual_deposit_limit;
                }
            });

            var accountTypes = ["roth", "sep", "traditional", "joint", "personal"];
            ms.accounts.sort(function(a, b) {
                return accountTypes.indexOf(a.type.split("-")[0]) > accountTypes.indexOf(b.type.split("-")[0]);
            });

            // if (msObj.metadata.months_to_goal || msObj.metadata.months_to_goal === 0) {
            //     ms.metadata.months_to_goal = msObj.metadata.months_to_goal;
            // } else
            if (msObj.metadata.target_date) {
                ms.metadata.months_to_goal = Math.round(moment(msObj.metadata.target_date).diff(new Date(), "months", true));
            }

            // msUtil.ms = ms;
            angular.copy(ms, msUtil.ms); //keep original reference

            return ms;
        };
        
        msUtil.updatePortfolioType = function(type) {
            switch (type) {
                case 'stocks_bonds':
                    msUtil.ms.extra.cash_start_percent = 0;
                    msUtil.ms.allocation.cash_start = 0;

                    if ( (!msUtil.ms.extra.cash_end_percent && msUtil.ms.extra.cash_end_percent !== 0)
                        || msUtil.ms.allocation.allocation_end === "0.0")
                    {
                        msUtil.ms.extra.cash_end_percent = Math.round(msUtil.ms.allocation.recommendation.cash_end * 100);
                        msUtil.ms.allocation.cash_end = msUtil.ms.allocation.recommendation.cash_end;
                    }

                    updateAllocation();

                    break;
                case 'stocks_bonds_cash':
                    if (msUtil.ms.extra.cash_start_percent && msUtil.ms.extra.cash_start_percent !== 100) {
                        //watcher might not be triggered if it's already set, so be sure to set it
                        msUtil.ms.allocation.cash_start = msUtil.ms.extra.cash_start_percent / 100;
                    } else if (   msUtil.ms.extra.cash_start_percent === 0 || msUtil.ms.extra.cash_start_percent === 100
                        || msUtil.ms.allocation.allocation_start === "0.0") {
                        //will be set to something new so watcher will set ratio
                        // unless it was already set to recommended in which case it won't, so set both just in case
                        msUtil.ms.extra.cash_start_percent = Math.round(msUtil.ms.allocation.recommendation.cash_start*100);
                        msUtil.ms.extra.cash_start = msUtil.ms.allocation.recommendation.cash_start;
                    }

                    if (msUtil.ms.extra.cash_end_percent && msUtil.ms.allocation.allocation_end !== "0.0") {
                        //watcher might not be triggered if it's already set, so be sure to set it
                        msUtil.ms.allocation.cash_end = msUtil.ms.extra.cash_end_percent / 100;
                    } else if (   msUtil.ms.extra.cash_end_percent === 0
                        || msUtil.ms.allocation.allocation_end === "0.0") {
                        //will be set to something new so watcher will set ratio
                        // unless it was already set to recommended in which case it won't, so set both just in case
                        msUtil.ms.extra.cash_end_percent = Math.round(msUtil.ms.allocation.recommendation.cash_end*100);
                        msUtil.ms.extra.cash_end = msUtil.ms.allocation.recommendation.cash_end;
                    }

                    updateAllocation();

                    break;
                case 'cash':
                    msUtil.ms.allocation.cash_start = 1;
                    msUtil.ms.allocation.cash_end = 1;
                    msUtil.ms.allocation.allocation_start = "0.0";
                    msUtil.ms.allocation.allocation_end = "0.0";
                    msUtil.ms.extra.portfolio_slug = "core";
                    break;
            }

            function updateAllocation() {
                if (  !msUtil.ms.allocation.allocation_start
                    || msUtil.ms.allocation.allocation_start < 0.9) {
                    //it's null or 0 or "0.0"

                    if (msUtil.ms.extra.allocation_start_value) {
                        //it's been set at some point or was set, put it back to what that was
                        msUtil.ms.allocation.allocation_start = msUtil.ms.extra.allocation_start_value.toFixed(1);
                    } else if (msUtil.ms.allocation.recommendation.allocation_start) {
                        msUtil.ms.extra.allocation_start_value = msUtil.ms.allocation.recommendation.allocation_start*1;
                        msUtil.ms.allocation.allocation_start = msUtil.ms.allocation.recommendation.allocation_start;
                    }
                }

                if (  !msUtil.ms.allocation.allocation_end
                    || msUtil.ms.allocation.allocation_end < 0.9) {
                    //it's null or 0 or "0.0"

                    if (msUtil.ms.extra.allocation_end_value) {
                        //it's been set at some point or was set, put it back to what that was
                        msUtil.ms.allocation.allocation_end = msUtil.ms.extra.allocation_end_value.toFixed(1);
                    } else if (msUtil.ms.allocation.recommendation.allocation_end) {
                        msUtil.ms.extra.allocation_end_value = msUtil.ms.allocation.recommendation.allocation_end*1;
                        msUtil.ms.allocation.allocation_end = msUtil.ms.allocation.recommendation.allocation_end;
                    }
                }

            }

        };

        /**
         * @deprecated signup only
         * @returns {string}
         */
        msUtil.togglePeriod = function() {
            switch (msUtil.ms.extra.period_unit) {
                case "Months":
                    msUtil.ms.extra.period_unit = "Years";
                    break;
                case "Years":
                /* falls through */
                default:
                    msUtil.ms.extra.period_unit = "Months";
                    break;
            }
            return msUtil.ms.extra.period_unit;
        };
        /**
         * On change of auto-deposit, the auto-deposit total will be calculated and used to find a new estimated date
         */
        msUtil.changedAutoDeposit = function() {
            msUtil.ms.extra.auto_deposit_set = msUtil.updateAutoDepositRemaining();
            if (msUtil.signup) {
                return msUtil.getEstimatedDate();
            } else {
                return msUtil.getNewEstimatedDate();
            }
        };

        msUtil.updateAutoDepositRemaining = function() {
            var remaining = msUtil.ms.auto_deposit.amount || 0; //prevent NaN on initializing create
            angular.forEach(msUtil.ms.accounts, function (account) {
                if (account.auto_deposit != null) {
                    remaining -= account.auto_deposit;
                }
            });
            //fix bad js math errors
            msUtil.ms.extra.auto_deposit_remaining = Math.round(remaining*100)/100;
            return true;
        };
        /**
         * Prevent account auto deposits from being more than total auto deposit
         * @param accounts
         * @param oldAccounts
         */
        msUtil.fixRemainingAutodeposits = function (accounts, oldAccounts) {
            if (msUtil.ms.extra.auto_deposit_remaining < 0) {
                //check if anythings gone up, and move it back to what it was before the change
                angular.forEach(accounts, function(account, index) {
                    if (account.auto_deposit > oldAccounts[index].auto_deposit) {
                        var fix = account.auto_deposit + msUtil.ms.extra.auto_deposit_remaining;
                        if (fix >= oldAccounts[index].auto_deposit) {
                            accounts[index].auto_deposit = fix;
                        } else {
                            accounts[index].auto_deposit = oldAccounts[index].auto_deposit;
                        }
                    }
                });
                msUtil.updateAutoDepositRemaining();
            }
        };
        msUtil.checkMultiAutoDepositPage = function(index) {
            if (msUtil.ms.extra.auto_deposit_remaining === 0) {
                return false;
            } else {
                return true;
            }
        };

        msUtil.iraAccounts = function() {
            var iraAccounts = [];
            angular.forEach(msUtil.ms.accounts, function (account) {
                if (account.type.includes('ira')) {
                    iraAccounts.push(account);
                }
            });
            if (!angular.equals(iraAccounts, msUtil.data.iraAccounts)) {
                msUtil.data.iraAccounts = iraAccounts;
            }
            return msUtil.data.iraAccounts;
        };
        msUtil.updateAnnualLimits = function() {
            var total = 0;
            angular.forEach(msUtil.ms.accounts, function (account) {
                if (account.annual_deposit_limit != null) {
                    total += account.annual_deposit_limit;
                }
            });
            //fix bad js math errors
            msUtil.ms.extra.annual_deposit_total = Math.round(total*100)/100;
        };
        msUtil.noNullAnnualLimits = function(skipIndex) {
            var noNull = true;
            msUtil.iraAccounts(msUtil.ms.accounts).forEach(function(account, i) {
                if (skipIndex === i) return;
                if (account.annual_deposit_limit==null) { //null or undefined
                    noNull = false;
                }
            });
            return noNull;
        };

        /** @deprecated signup only **/
        msUtil.setMonthsAndTarget = function() {
            var months;
            months = msUtil.ms.extra.period;
            if (msUtil.ms.extra.period_unit === "Years") {
                months *= 12;
            }
            msUtil.ms.metadata.months_to_goal = months * 1;

            msUtil.ms.metadata.target_date = moment().add(msUtil.ms.metadata.months_to_goal, "months").format('YYYY-MM-DD');

        };

        /**
         * Will take period_months and period_years and calculate/set months_to_goal and target_date
         */
        msUtil.setDatesByMonthYear = function() {
            msUtil.ms.metadata.months_to_goal = (msUtil.ms.extra.period_months * 1) + (msUtil.ms.extra.period_years * 12);
            msUtil.ms.metadata.target_date = moment().add(msUtil.ms.metadata.months_to_goal, "months").format('YYYY-MM-DD');
        };

        /**
         * Used on create milestone only
         */
        msUtil.setDefaults = function() {
            var data;

            data = msUtil.data.availableDefaults[msUtil.ms.type];

            //should hold true for all create
            msUtil.ms.type_real = msUtil.ms.type;

            msUtil.ms.target_amount = null;
            msUtil.ms.initial_deposit = null;
            msUtil.ms.metadata = {};
            msUtil.ms.extra = {};
            angular.forEach(msUtil.data.extraDefaults, function(val, key) {
                msUtil.ms.extra[key] = val;
            });

            msUtil.ms.name = data.name;
            if (msUtil.signup) {
                msUtil.ms.auto_deposit.frequency = msUtil.ms.extra.auto_deposit_frequency_recommended || "monthly";
            }

            msUtil.ms.accounts[0].auto_deposit = null;
            msUtil.ms.accounts[0].type = data.accounts[0].type;
            msUtil.ms.extra.account_type = data.accounts[0].type;
            msUtil.ms.accounts[0].name = data.accounts[0].name;

            //set the defaults each time the type is changed
            if (msUtil.signup) {
                msUtil.setTags();
            }
            msUtil.setTargetDefaults();
            msUtil.setAllocationDefaults();

            switch (msUtil.ms.type) {
                case msUtil.views.rainy_day.type:
                    break;
                case msUtil.views.retirement.type:
                    break;
                case msUtil.views.build_wealth.type:
                    break;
                case msUtil.views.custom.type:
                    break;
                case msUtil.views.cash.type:
                    if (msUtil.signup) {
                        msUtil.ms.metadata.purpose = "Other";
                    }
                    break;
            }

            msUtil.ms.extra.defaultsSet = true;
        };
        /**
         * @deprecated signup only
         */
        msUtil.setTags = function() {
            if (!msUtil.views[msUtil.ms.type]) { return; }

            msUtil.ms.extra.tag = msUtil.views[msUtil.ms.type].tag;
            switch (msUtil.ms.type) {
                case msUtil.views.build_wealth.type:
                    break;
                case msUtil.views.rainy_day.type:
                    if (30000 <= msUtil.data.income && msUtil.data.income <= 100000) {
                        msUtil.ms.extra.months_expenses = msUtil.getRainyXMonths(msUtil.ms.target_amount);
                        msUtil.ms.extra.tag = "Cover " + msUtil.ms.extra.months_expenses + " months expenses";
                    }
                    break;
                case msUtil.views.custom.type:
                    break;
                case msUtil.views.retirement.type:
                    var yearly = $filter('wbCurrency')(msUtil.ms.metadata.spend_per_year || 0);
                    msUtil.ms.extra.tag = "Spend " + yearly + " per year";
                    break;
            }
        };

        msUtil.setTargetDefaults = function() {
            var data = msUtil.data.availableDefaults[msUtil.ms.type];
            switch (msUtil.ms.type) {
                case msUtil.views.build_wealth.type:
                    break;
                case msUtil.views.cash.type:
                    msUtil.ms.target_amount = data.target_amount;
                    msUtil.ms.metadata.target_date = data.metadata.target_date;
                    msUtil.ms.metadata.months_to_goal = Math.round(moment(data.metadata.target_date).diff(moment(new Date()), "months", true));

                    msUtil.ms.extra.recommended_months = msUtil.ms.metadata.months_to_goal;
                    msUtil.ms.extra.period_months = msUtil.ms.metadata.months_to_goal % 12;
                    msUtil.ms.extra.period_years = Math.floor(msUtil.ms.metadata.months_to_goal / 12);

                    /** @deprecated signup only **/
                    msUtil.ms.extra.period = msUtil.ms.metadata.months_to_goal;
                    /** @deprecated signup only **/
                    msUtil.ms.extra.period_unit = "Months";
                    break;
                case msUtil.views.rainy_day.type:
                    msUtil.ms.target_amount = data.target_amount;
                    msUtil.ms.metadata.months_to_goal = data.metadata.months_to_goal;
                    msUtil.ms.extra.recommended_months = msUtil.ms.metadata.months_to_goal;
                    msUtil.ms.extra.period_months = msUtil.ms.metadata.months_to_goal % 12;
                    msUtil.ms.extra.period_years = Math.floor(msUtil.ms.metadata.months_to_goal / 12);

                    /** @deprecated signup only **/
                    msUtil.ms.extra.period = data.metadata.months_to_goal;
                    /** @deprecated signup only **/
                    msUtil.ms.extra.period_unit = "Months";
                    break;
                case msUtil.views.custom.type:
                    msUtil.ms.target_amount = data.target_amount;
                    msUtil.ms.metadata.target_date = data.metadata.target_date;
                    msUtil.ms.metadata.months_to_goal = Math.round(moment(data.metadata.target_date).diff(moment(new Date()), "months", true));

                    msUtil.ms.extra.recommended_months = msUtil.ms.metadata.months_to_goal;
                    msUtil.ms.extra.period_months = msUtil.ms.metadata.months_to_goal % 12;
                    msUtil.ms.extra.period_years = Math.floor(msUtil.ms.metadata.months_to_goal / 12);

                    /** @deprecated signup only **/
                    msUtil.ms.extra.period = msUtil.ms.metadata.months_to_goal;
                    /** @deprecated signup only **/
                    msUtil.ms.extra.period_unit = "Months";
                    break;
                case msUtil.views.retirement.type:
                    msUtil.ms.metadata.spend_per_year = data.metadata.spend_per_year;
                    msUtil.ms.metadata.retirement_age = data.metadata.retirement_age;
                    msUtil.ms.metadata.life_expectancy = data.metadata.life_expectancy;
                    msUtil.ms.metadata.social_security_age = data.metadata.social_security_age;
                    msUtil.ms.metadata.social_security_payout = data.metadata.social_security_payout;
                    msUtil.ms.extra.social_security_percent   = data.metadata.social_security_payout * 100;
                    break;
                case msUtil.views.new_home.type:
                    // msUtil.ms.target_amount = data.target_amount;
                    msUtil.ms.metadata.purchase_price = data.metadata.purchase_price;
                    msUtil.ms.target_amount = msUtil.ms.metadata.purchase_price*0.20;

                    msUtil.ms.metadata.target_date = data.metadata.target_date;
                    msUtil.ms.metadata.months_to_goal = Math.round(moment(data.metadata.target_date).diff(moment(new Date()), "months", true));
                    msUtil.ms.extra.recommended_months = msUtil.ms.metadata.months_to_goal;
                    msUtil.ms.extra.period_months = msUtil.ms.metadata.months_to_goal % 12;
                    msUtil.ms.extra.period_years = Math.floor(msUtil.ms.metadata.months_to_goal / 12);
                    break;
                case msUtil.views.new_car.type:
                    // msUtil.ms.target_amount = data.target_amount;
                    msUtil.ms.metadata.purchase_price = data.metadata.purchase_price;
                    msUtil.ms.target_amount = msUtil.ms.metadata.purchase_price*0.20;

                    msUtil.ms.metadata.target_date = data.metadata.target_date;
                    msUtil.ms.metadata.months_to_goal = Math.round(moment(data.metadata.target_date).diff(moment(new Date()), "months", true));
                    msUtil.ms.extra.recommended_months = msUtil.ms.metadata.months_to_goal;
                    msUtil.ms.extra.period_months = msUtil.ms.metadata.months_to_goal % 12;
                    msUtil.ms.extra.period_years = Math.floor(msUtil.ms.metadata.months_to_goal / 12);
                    break;
            }
        };
        msUtil.setAllocationDefaults = function() {
            // var data = msUtil.data.availableDefaults[msUtil.ms.type];

            if (msUtil.ms.allocation.recommendation && msUtil.ms.allocation.recommendation.allocation_start) {
                msUtil.ms.extra.allocation_start_value = msUtil.ms.allocation.recommendation.allocation_start*1 || null;
                msUtil.ms.extra.allocation_end_value = msUtil.ms.allocation.recommendation.allocation_end*1 || null;
            } else if (msUtil.ms.allocation.recommended) {
                //fallback for signup and if there's only a recommended but no glide recommendation
                msUtil.ms.allocation.allocation_model = msUtil.ms.allocation.recommended;
                msUtil.ms.extra.allocation_value = msUtil.ms.allocation.recommended*1 || null;
            }

            switch (msUtil.ms.type) {
                case msUtil.views.build_wealth.type:
                    break;
                case msUtil.views.rainy_day.type:
                    break;
                case msUtil.views.cash.type:
                    msUtil.ms.extra.allocation_value = 0;
                    msUtil.ms.allocation.allocation_model = "0.0";

                    if (!msUtil.signup) {
                        msUtil.ms.extra.cash_start_percent = 100;
                        msUtil.ms.allocation.cash_start = 1;

                        msUtil.ms.extra.cash_end_percent = 100;
                        msUtil.ms.allocation.cash_end = 1;

                        msUtil.ms.extra.allocation_start_value = 0;
                        msUtil.ms.allocation.allocation_start = "0.0";

                        msUtil.ms.extra.allocation_end_value = 0;
                        msUtil.ms.allocation.allocation_end = "0.0";
                    }
                    break;
                case msUtil.views.custom.type:
                    break;
                case msUtil.views.retirement.type:
                    if (msUtil.signup) {
                        msUtil.setRetirementAdditionalDefaults();
                    }
                    break;
            }
        };
        /** @deprecated signup only **/
        msUtil.setRetirementAdditionalDefaults = function() {
            var data = msUtil.data.availableDefaults['retirement'];
            //need to store original value in extra to match against if it's changed
            if (data) {
                msUtil.ms.extra.life_expectancy_initial = data.metadata.life_expectancy;
                msUtil.ms.metadata.life_expectancy = data.metadata.life_expectancy;

                msUtil.ms.metadata.social_security_age = data.metadata.social_security_age;
                msUtil.ms.metadata.social_security_payout = data.metadata.social_security_payout;
                msUtil.ms.extra.social_security_percent = data.metadata.social_security_payout * 100;
                msUtil.updateRetirementAge();
            }
        };
        msUtil.setAutoDepositDefaults = function() {
            msUtil.ms.auto_deposit.amount = msUtil.ms.auto_deposit.recommended;
            if (msUtil.signup) {
                msUtil.ms.auto_deposit.frequency = msUtil.ms.extra.auto_deposit_frequency_recommended;
            }
        };
        msUtil.setSectionDefaults = function(section) {
            switch (section) {
                case 'target':
                    msUtil.setTargetDefaults();
                    break;
                case 'allocation':
                    msUtil.setAllocationDefaults();
                    break;
                case 'auto-deposit':
                    msUtil.setAutoDepositDefaults();
                    break;
            }
        };

        /**
         * @deprecated signup only
         * @param amount
         * @param income
         * @returns {number}
         */
        msUtil.getRainyXMonths = function(amount, income) {
            income = income || msUtil.data.income;

            var months = amount / ( ( income/12 ) * 0.5 );
            if (months) {
                //round to closest 0.25
                months = parseFloat((Math.round(months * 4) / 4).toFixed(2));
            } else {
                months = 0;
            }
            return months;
        };

        /**
         * @deprecated signup only
         *
         * stocks and bonds: If client chooses Rainy Day target amount ≥ recommended amount and < 150% recommended amount
         *
         * both: If client chooses Rainy Day target amount ≥ 150% recommended amount
         *
         * all cash: If client chooses target amount < recommended amount
         *
         * @returns {*}
         */
        msUtil.getPortfolioTypeRecommended = function() {
            var recommendation = null;

            if (msUtil.ms.type === 'rainy_day') {
                var income = msUtil.data.income || 30000;
                if (30000 <= income && income <= 100000) {
                    //1.5 months
                    var recommended_target = (income / 12) * 1.5;
                    var target_amount = msUtil.ms.target_amount;

                    if (recommended_target <= target_amount && target_amount < recommended_target * 1.5) {
                        recommendation = 'both';
                    } else if (target_amount >= recommended_target * 1.5) {
                        recommendation = 'market';
                    } else if (target_amount < recommended_target) {
                        recommendation = 'cash'
                    }
                }
            } else if (msUtil.ms.type === 'retirement') {
                var yearsTillRetirement = moment(msUtil.data.birth_date, 'YYYY-MM-DD')
                                                    .add(msUtil.ms.metadata.retirement_age, "years")
                                                    .diff(new Date(), "years");

                if (yearsTillRetirement  <= 15) {
                    recommendation = 'both';
                } else if (yearsTillRetirement > 15) {
                    recommendation = 'market';
                }
            }
            return recommendation;
        };

        /**
         * @deprecated signup only
         * Signup only
         * @param income
         */
        msUtil.setIncome = function(income) {
            msUtil.data.income = income;
        };
        /**
         * @deprecated signup only
         * @param risk
         */
        msUtil.setRiskScore = function(risk) {
            msUtil.data.risk_score = risk;
        };
        msUtil.setBirthDate = function(date) {
            msUtil.data.birth_date = date;
            msUtil.data.age = moment(new Date()).diff(moment(date, "YYYY-MM-DD"), 'years');
        };
        msUtil.getMaxSSAge = function() {
            return msUtil.ms.metadata.life_expectancy < 85 ? msUtil.ms.metadata.life_expectancy : 85
        };
        msUtil.getMinLifeExpectancy = function() {
            return msUtil.ms.metadata.retirement_age >= 63 ? msUtil.ms.metadata.retirement_age + 1 : 63
        };
        msUtil.updateRetirementAge = function() {
            if (msUtil.ms.metadata) {
                if (msUtil.ms.extra.life_expectancy_initial < msUtil.ms.metadata.life_expectancy) {
                    //always set life_expectancy back to initial/default value
                    msUtil.ms.metadata.life_expectancy = msUtil.ms.extra.life_expectancy_initial;
                }
                if (msUtil.ms.metadata.retirement_age >= msUtil.ms.metadata.life_expectancy) {
                    msUtil.ms.metadata.life_expectancy = msUtil.ms.metadata.retirement_age + 1;
                }
            }
        };

        msUtil.setScopeListeners = function($scope) {

        };

        msUtil.prepareSubmitData = function(ms) {
            var data;
            
            data = angular.copy(ms);


            if (!msUtil.signup) {
                delete data.allocation.cash;

                delete data.allocation.allocation_model;

                //no longer needed since start/end are set to portfolio_slug when selected
                if (ms.extra.portfolio_slug && ms.extra.portfolio_slug !== "core") {
                    data.allocation.allocation_start = ms.extra.portfolio_slug;
                    data.allocation.allocation_end = ms.extra.portfolio_slug;
                }
                if (data.type === msUtil.views.build_wealth.type || data.type === 'other') {
                    data.allocation.allocation_end = data.allocation.allocation_start;
                    data.allocation.cash_end = data.allocation.cash_start;
                }



            } else if (msUtil.signup) {

                if (!isNaN(data.allocation.allocation_model) && data.allocation.allocation_model < 0.9) {
                    data.allocation.allocation_model = "0.0";
                    data.allocation.cash = 1;
                }

                //don't want to send a null cash_allocation, so remove if it's invalid
                if (!data.allocation.cash && data.allocation.cash !== 0) {
                    delete data.allocation.cash;
                }
            }

            var newAccounts = [];
            angular.forEach(data.accounts, function (account) {
                if (data.auto_deposit.amount === 0 || account.auto_deposit === 0 || !account.auto_deposit) {
                    account.auto_deposit = 0;
                }

                if (!account.account_number) {
                    //can no longer create new accounts in milestone
                    return;
                }
                newAccounts.push({
                    account_number: account.account_number,
                    annual_deposit_limit: account.annual_deposit_limit
                });

            });
            data.accounts = newAccounts;

            if (data.accounts.length === 1) {
                // data.accounts[0].auto_deposit = data.auto_deposit.amount;
            }

            if (data.id) {
                data.msId = data.id;

            } else {
                //must be create

                // if (data.initial_deposit && data.accounts[0]) {
                //     //the first account will always be the new one that gets the initial deposit
                //     data.accounts[0].initial_deposit = data.initial_deposit;
                // }
                // if (data.extra.contribution_year && data.accounts[0]) {
                //     //if there is a contribution year, the first account will be the new IRA
                //     data.accounts[0].contribution_year = data.extra.contribution_year;
                // }
            }

            delete data.auto_deposit;
            delete data.initial_deposit;
            delete data.metrics;

            delete data.extra;

            if (data.type !== msUtil.views.rainy_day.type) {
                delete data.metadata.months_to_goal;
            }
            if (data.type === msUtil.views.build_wealth.type) {
                delete data.metadata.target_date;
            }

            if (data.type_real) {
                data.type = data.type_real;
                delete data.type_real;
            }

            return data;
        };

        msUtil.clearMS = function() {
            angular.copy(msUtil.msDefault, msUtil.ms);
        };


        return msUtil;
    }]);

})();
