(function() {
	'use strict';
	angular.module('wbUtilities').factory('AuthKey', ['$window', '$sha', 'CommonConstant', function ($window, $sha, CommonConstant) {

		var auth = {};

		var SECRET = {
				deployment: CommonConstant.site.signature_secret || "",
				// prod:  "lDzJ2Es3o4xMdG8IxR1v",
				// stag:  "svLCjAxc8ucga29jSsk2",
				// dev:   "wKeaqd6OBdfDny35pKMi",
			};

		auth.getSigKey = function(string, time) {
			var strBase64, hash, result;
			string || (string=""); // jshint ignore:line

            if (!time) {
                time = moment().unix();
            }

			$sha.setConfig({
				algorithm: 'SHA-256',
				inputType: 'TEXT',
				returnType: 'HEX',
				secretType: 'TEXT'
			});
			strBase64 = $window.btoa(string+"_"+time);

			hash = $sha.hmac(strBase64, SECRET.deployment);

			result = $window.btoa(strBase64 + "." + hash);

			return result;
		};

		auth.getApiKey = function() {
			return CommonConstant.site.api_gateway_key;
		};

		return auth;
	}]);
})();
