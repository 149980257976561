angular.module('wbPublic')
.run(['$rootScope', '$state', '$stateParams', '$transitions', '$window', '$location', 'Message', 'Session', 'Track', 'VisibilityChange',
function ($rootScope, $state, $stateParams, $transitions, $window, $location, Message, Session, Track, VisibilityChange) {

    Track.send("Page Viewed", "pageview", {});

    $rootScope.wbBeta = "wbBeta" in $location.search();

    $rootScope.$state = $state;
    $rootScope.$stateParams = $stateParams;
    $rootScope.$transitions = $transitions;

    //used on invite page to see if the page was hidden or not for mobile
    VisibilityChange.configure({broadcast: true});

    var message = Session.get("message");
    if (message) {
        Message.add(message);
        Session.remove("message");
    }

}]);
