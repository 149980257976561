(function() {
    'use strict';
    angular.module('wbPublic').controller('InviteFormController', ['$scope', '$window', 'InviteService', function($scope, $window, InviteService) {
        $scope.forms = {};
        $scope.invite = {};

        $scope.InviteService = InviteService;


        $scope.$watch(function() { return InviteService.referral; }, function(referral) {
            $scope.referral = referral;
        });

        $scope.goToInvite = function() {
            var email = $window.encodeURIComponent($scope.InviteService.modelEmail);
            var code = $scope.referral.referralCode || "";
            var alias = $scope.referral.alias || "";
            $window.location.href = "/dashboard/signup-request/" + code + "?email=" + email + "&alias=" + alias;
        };

        $scope.getAxosEnrollLink = function() {
            return InviteService.getAxosEnrollLink();
        };

    }]);
})();
