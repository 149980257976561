(function() {
    'use strict';
    angular.module('wbUtilities').factory('Auth', ['Session', 'AuthKey', 'jwtHelper', function (Session, AuthKey, jwtHelper) {
        var auth = {};

        auth.setTarget = function (path, params) {
            if (!params) {
                params = {};
            }
            return Session.setCookie('loginTarget', {
                path: path,
                params: params,
                username: auth.getUsername()
            }, 1/24); //1 hour
        };

        /**
         * Target of last attempted page of current user
         * @returns {*}
         */
        auth.getTarget = function () {
            var target = Session.getCookie('loginTarget');

            var currentUsername = auth.getUsername();
            if (target && target.username && currentUsername && target.username !== currentUsername) {
                target = null;
            }

            if (!target) {
                target = {
                    path: "base.overview",
                    params: {},
                    username: currentUsername
                }; //return this object rather than from cookie in case cookies disabled on site
                auth.setTarget(target.path, target.params);
            }
            return target;
        };

        auth.setUsername = function(username) {
			return Session.set('authUsername', username);
        };
        auth.getUsername = function() {
			return Session.get('authUsername');
        };

        auth.getSigKey = function(string, time) {
            if (!string) {
                string = auth.getUsername();
            }
            return AuthKey.getSigKey(string, time);
        };
        auth.getApiKey = function() {
            return AuthKey.getApiKey();
        };

        auth.setToken = function (token) {
            var sessionSet = Session.set('authToken', token);

            try {
                var pl = jwtHelper.decodeToken(token);
                if (pl && pl.client_id) {
                    auth.setClientId(pl.client_id);
                }
            } catch (err) {}

            return sessionSet;
        };

        auth.getToken = function () {
            return Session.get('authToken');
        };

        auth.setClientId = function (clientId) {
            return Session.set('authClientId', clientId);
        };

        auth.getClientId = function () {
            return Session.get('authClientId');
        };

        auth.setAxosRedirect = function (redirected) {
            return Session.set('axosRedirect', redirected);
        };
        auth.isAxosRedirect = function () {
            return !!Session.get('axosRedirect');
        };

        auth.isLoggedIn = function () {
            return !!Session.get('authToken');
        };
        
        auth.logout = function () {
            Session.remove('authUsername');
            Session.remove('authClientId');
            Session.remove('axosRedirect');
            return Session.remove('authToken');
        };

        return auth;
    }]);
})();
