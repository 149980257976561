(function() {
    'use strict';
    angular.module('wbPublic').controller('ReminderUnsubscribeController', ['$scope', '$http', 'Request', '$location', '$window', 'Session', 'Track', function ($scope, $http, Request, $location, $window, Session, Track) {

        $scope.pageData = {
            page: "main",
            showUnsubscribe: $location.path().includes('unsubscribe'),
            error: {schedule: null, unsub: null},
            reminderDate: moment().add(3, 'day').toDate(),
            sending: false,
            sendingSchedule: false,
            sendingUnsub: false,
        };

        $scope.data = {
            email: null,
            type: null, //client or waitlist-invite
            existScheduling: null
        };

        $scope.code = $location.path().split('/').filter(function(v) {return v;}).pop();

        $scope.datepickerOptions = {
            minDate: moment().add(1, 'day').toDate(),
            maxDate: moment().add(30, 'days').toDate(),
            formatDayHeader: 'EEE', formatDay: 'd', showWeeks: false
        };

        $scope.initPage = function() {
            Request.make('signup_invite_reminders', {code: $scope.code}).then(function(response) {
                var data = response.data.response;

                // $scope.status = data.status; //unused
                $scope.data.email = data.email;
                $scope.data.type = data.type;
                $scope.data.existScheduling = data.existScheduling;
                if ($scope.data.existScheduling) {
                    $scope.pageData.reminderDate = moment($scope.data.existScheduling);
                }

                if ($scope.pageData.showUnsubscribe && $scope.data.type === "client") {
                    $window.location.replace("/dashboard/profile/contact");
                }

            }).catch(function(response) {
                var errorMsg = "Sorry, there was an issue with your invite code.";
                if (response.data && response.data.message) {
                    errorMsg = response.data.message;
                }
                var message = {
                    "type": "warning",
                    "text": errorMsg
                };
                Session.set("message", message);

                $window.location.replace("/managed-portfolios");
            });
        };
        $scope.initPage();

        function resetError() {
            $scope.pageData.error.schedule = null;
            $scope.pageData.error.unsub = null;
        }

        $scope.schedule = function (reminderDate) {
            resetError();
            $scope.pageData.sending = true;
            $scope.pageData.sendingSchedule = true;
            var date = moment(reminderDate).format("YYYY-MM-DD");
            Request.make("signup_invite_reminders_send", {'email': $scope.data.email, 'date': date}).then(function (response) {

                if (response.data.status === 'success') {
                    angular.element("html, body").scrollTop(0);
                    $scope.pageData.page = "rescheduled";
                    Track.send("Set Reminder Date");
                } else if (response.data.status === 'error') {
                    $scope.pageData.error.schedule = "Unable to schedule at this time, please try another date.";
                }

            }).catch(function (response) {
                $scope.pageData.error.schedule = "Unable to schedule at this time, please try another date.";
            }).finally(function() {
                $scope.pageData.sending = false;
                $scope.pageData.sendingSchedule = false;
            });
        };

        $scope.unsubscribe = function () {
            resetError();
            $scope.pageData.sending = true;
            $scope.pageData.sendingUnsub = true;
            Request.make("signup_invite_unsubscribe", {'code': $scope.code}).then(function () {
                angular.element("html, body").scrollTop(0);
                $scope.pageData.page = "unsub-success";
            }).catch(function (response) {
                $scope.pageData.error.unsub = response.error.message;
            }).finally(function() {
                $scope.pageData.sending = false;
                $scope.pageData.sendingUnsub = false;
            });
        };
        
    }]);
})();
