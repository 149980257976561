(function() {
    'use strict';
    angular.module('wbUtilities').directive('inputWrapAurea', ['$timeout', '$interpolate', function ($timeout, $interpolate) {
        return {
            restrict: 'E',
            replace: true,
            transclude: true,
            //template: '<div class="form-group input-container">{{scope.inputScope}}<label>hi there</label><div ng-transclude></div></div>',
            templateUrl: '/common/src/app/directives/input/input.tpl.html',
            scope: true,
            link: function (scope, element, attrs, ctrls, transclude) {

                scope.showError = function() {
                    if (!scope.inputScope) return false;

                    return (  scope.ngModel.$invalid
                        &&  (
                            scope.ngModel.$touched
                            || (scope.ngForm && scope.ngForm.showErrors)
                            || scope.inAttrs.showError === 'true'
                            || scope.inAttrs.type === 'hidden'
                        )
                    );
                };

                if (scope.inputScope) {
                    scope.ngModel = scope.inputScope.objs.ngModel;
                    scope.ngForm = scope.inputScope.objs.ngForm;
                    scope.inAttrs = scope.inputScope.objs.attrs;
                    scope.validateMessages = {};
                    if (scope.ngModel) {
                        scope.$watchCollection("ngModel.$error", function (errors = {}) {
                            //re-eval each time in case something changes
                            if ("validateMessages" in scope.inAttrs) {
                                scope.validateMessages = scope.inputScope.$eval(scope.inAttrs.validateMessages);
                            }
                            var message = null;
                            angular.forEach(errors, function (valid, errorKey) {
                                if (!message) {
                                    if (errorKey in scope.validateMessages) {
                                        message = scope.validateMessages[errorKey];
                                    } else {
                                        if ('*' in scope.validateMessages) {
                                            message = scope.validateMessages['*'];
                                        } else {
                                            switch (errorKey) {
                                                case "email":
                                                    message = "Email address is invalid";
                                                    break;
                                                case "required":
                                                    message = (scope.inAttrs.placeholder ? ucword(scope.inAttrs.placeholder) : "Field") + " is required";
                                                    break;
                                            }
                                        }
                                    }
                                }
                            });
                            if (Object.keys(errors).length) {
                                if (message === null) {
                                    message = "There is an issue with the input";
                                }
                                scope.errorMessage = $interpolate(message)(scope);
                            } else {
                                scope.errorMessage = null;
                            }
                        });

                        if (attrs.inline === "true") {
                            scope.inline = true;
                            if (scope.ngModel.$options.getOption('debounce')) {
                                scope.inputScope.objs.element.on('input', function () {
                                    //need to apply the scope to get updated $viewValue when debounce is used
                                    scope.$apply();
                                });
                            }
                        }

                    } else {
                        scope.ngModel = {};
                    }
                    if (scope.inAttrs.type === "radio") {
                        element.find('input[type=radio] + label').prepend("<span class='dot'></span>");
                    }
                    if (element.hasClass('input-percent')) {
                        element.find('input').after("<span class='percent-marker'>%</span>");
                    }
                }

                scope.errorAlignment = "text-left";

                function ucword(input) {
                    if (!input) {
                        return "";
                    }
                    return input.replace(/(?:^|\s)\S/g, function(a) { return a.toUpperCase(); });
                }
            }
        };
    }]).directive('inputAurea', ['$timeout', '$filter', function ($timeout, $filter) {
        return {
            restrict: 'A',
            scope: false, //false makes out direct parent
            require: ["?ngModel", "^?form"],
            compile: function(tElement, attrs, transclude) {

                /*
                //auto tooltip based on placeholder (will need re-$compile)
                //http://stackoverflow.com/questions/19224028/add-directives-from-directive-in-angularjs/19228302#19228302
                if (!attrs.tooltip && attrs.placeholder && attrs.ngModel
                    && (      ["text", "password", "email", "number"].indexOf(attrs.type) > -1
                          ||  tElement[0].tagName == "TEXTAREA"
                       )
                ) {
                    tElement.attr("tooltip", attrs.placeholder);
                    tElement.attr("tooltip-trigger", "focus");
                    tElement.attr("tooltip-enable", "!!" + attrs.ngModel);
                }
                */

                return function (scope, element, attrs, ctrls) {
                    var $element = angular.element(element);
                    /*jshint -W030 */
                    attrs.type || (attrs.type = "");

                    if (element[0].tagName.toLowerCase() == 'select') {
                        attrs.type = "select";
                    }
                    if (element[0].tagName.toLowerCase() == 'textarea') {
                        attrs.type = "textarea";
                    }

                    scope.objs = {
                        element: element,
                        ngModel: ctrls[0],
                        ngForm: ctrls[1],
                        attrs: attrs
                    };
                    scope.ngModel = scope.objs.ngModel;
                    scope.ngForm = scope.objs.ngForm;

                    //add id for labels if not provided
                    if (!attrs.id) {
                        attrs.id = Math.random().toString(36).substring(2);
                        $element.prop('id', attrs.id);
                    }


                    if (attrs.focus == "true") {
                        $timeout(function () {
                            $element.focus();
                        }, 100);
                    }

                    if (["text", "password", "email", "number"].indexOf(attrs.type.toLowerCase()) > -1 || (["SELECT", "TEXTAREA"].indexOf(element[0].tagName) > -1) ) {
                        $element.addClass("form-control");
                    }
                    if (['checkbox', 'radio'].indexOf(attrs.type.toLowerCase()) > -1) {
                        //$element.parent("label").addClass("checkbox");
                        $element.next("label").prop('for', attrs.id);
                    }
                    if ($element.prop("tagName") == "BUTTON") {
                        $element.addClass("btn");
                    }

                    //give parent access to data
                    scope.$parent.inputScope = scope;


                    if (scope.objs.ngModel) {
                        scope.$watch(function() { return scope.$parent.showError && scope.$parent.showError(); }, function(showError) {
                            if (showError) {
                                $element.addClass('is-invalid').attr("aria-invalid", true).attr("aria-describedby", "error-"+attrs.id);
                            } else {
                                $element.removeClass('is-invalid').attr("aria-invalid", null).attr("aria-describedby", null);
                            }
                        });

                        //TODO: make it's own directive
                        if ("currency" in attrs) {
                            var decimal = 2;
                            if (attrs.currencyDecimal == "false") {
                                decimal = 0;
                            }

                            //@TODO own directive?
                            scope.objs.ngModel.$parsers.unshift(function(value) {
                                //parse $viewValue into proper value for model
                                //prevent more than 2 decimals
                                var split = value.split('.');
                                if (split[1] && split[1].length > 2) {
                                    value = split[0] + '.' + split[1].slice(0,2);
                                    scope.objs.ngModel.$setViewValue(value);
                                    scope.objs.ngModel.$render();
                                }

                                var cleanValue;
                                cleanValue = value;
                                if (typeof value == "string") {
                                    cleanValue = parseFloat(cleanNumber(cleanValue));
                                }
                                cleanValue = parseFloat(cleanValue.toFixed(decimal));

                                return isNaN(cleanValue) ? false : cleanValue;
                            });
                            scope.objs.ngModel.$formatters.unshift(function(value) {
                                //format ngModel.$viewValue when watched object is changed
                                if (!$element.is(":focus")) {
                                    return formatCurrency(value, decimal);
                                }
                                return parseFloat(value).toFixed(decimal);
                            });

                            var step = 1;
                            $element.on('focus', function (event) {
                                //remove commas on focus
                                var value;
                                if (scope.objs.ngModel.$modelValue) {
                                    value = scope.objs.ngModel.$modelValue.toFixed(decimal);
                                } else if (scope.objs.ngModel.$viewValue) {
                                    value = cleanNumber(scope.objs.ngModel.$viewValue);
                                }
                                scope.objs.ngModel.$setViewValue(value, event);
                                scope.objs.ngModel.$render();
                            }).on('blur', function (event) {
                                //format like number on blur
                                //need timeout to deal with debounce with updateOn blur
                                $timeout(function() {
                                    var value;
                                    if (scope.objs.ngModel.$modelValue) {
                                        value = scope.objs.ngModel.$modelValue;
                                    } else if (scope.objs.ngModel.$viewValue) {
                                        value = parseFloat(cleanNumber(scope.objs.ngModel.$viewValue));
                                    }
                                    scope.objs.ngModel.$setViewValue(formatCurrency(value, decimal), event);
                                    scope.objs.ngModel.$render();
                                });
                            }).on('keydown', function (event) {
                                //increase value on key up/down
                                //only keydown event registers arrow keys
                                var value;
                                var key = event.which;
                                if (scope.objs.ngModel.$viewValue) {
                                    value = parseFloat(cleanNumber(scope.objs.ngModel.$viewValue));
                                    step = getStep(value);
                                } else {
                                    value = 10;
                                    step = 0;
                                }
                                switch (true) {
                                    case key === 38: //up
                                        value += step;
                                        scope.objs.ngModel.$setViewValue(value.toFixed(decimal), event);
                                        scope.objs.ngModel.$render();
                                        break;
                                    case key === 40: //down
                                        value -= step;
                                        if (value < 0 && !attrs.negative) {
                                            value = 0;
                                        }
                                        scope.objs.ngModel.$setViewValue(value.toFixed(decimal), event);
                                        scope.objs.ngModel.$render();
                                        break;
                                }

                            }).on('keypress', function (event) {
                                //ignore non number keys (including ".", "-" and ",")
                                //only kepress gives charCode
                                var char = event.charCode;
                                if (char && !(/[\-\d\.]/g.test(String.fromCharCode(char)))) {
                                    event.preventDefault();
                                    return false;
                                }
                            });
                        }

                        if ("ngParser" in attrs) {
                            var parser = scope.inputScope.$eval(attrs.ngParser);
                            if (typeof parser === "function") {
                                scope.objs.ngModel.$parsers.unshift(parser);
                            }
                        }
                        if ("ngFormatter" in attrs) {
                            var formatter = scope.inputScope.$eval(attrs.ngFormatter);
                            if (typeof formatter === "function") {
                                scope.objs.ngModel.$formatters.unshift(formatter);
                            }
                        }
                    }

                    /**
                     * Return adjusted step value for changing value with up/down arrows
                     * @param value
                     * @returns {*}
                     */
                    function getStep(value) {
                        if (parseInt(attrs.step)) {
                            return parseInt(attrs.step);
                        }

                        var step;
                        var length = parseInt(value).toString();
                        length = length.length;
                        step = Math.pow(10, length-1);
                        if (step*2 > value) {
                            step = step * 0.1;
                        }
                        if (step < 1) {
                            step = 1;
                        }
                        return step;
                    }


                    function formatCurrency(value, decimal) {
                        return $filter('currency')(value, '', decimal);
                        // if (decimal) {
                        // } else {
                        //     return $filter('wbCurrency')(value, '');
                        // }
                    }

                    /**
                     * Will take a invalid formatted number and clean invalid characters
                     * @param value
                     * @returns {*}
                     */
                    function cleanNumber(value) {
                        if (!attrs.negative) {
                            value = value.replace(/-/g, "");
                        }
                        return value.replace(/([^\-\d\.])/g, "").replace(/(?!^)-/g, "");
                    }

                };
            }
        };
    }]).directive('fieldset', ['$window', function ($window) {
        /**
         * This is to fix IE where a disabled fieldset doesn't disable all elements within it
         */
        return {
            restrict: 'E',
            link: function (scope, element, attrs) {
                //feature detection doesn't work
                //if (angular.isUndefined(element.prop('disabled'))) { //only watch if the browser doesn't support disabled on fieldsets
                if ("ActiveXObject" in $window) { //any IE period

                    scope.$watch(function () { return element.attr('disabled'); }, function (disabled) {
                        if (disabled) {
                            element.find('input, select, textarea, button')
                                .prop('disabled', true)
                                .attr('fieldset-disabled',"")
                                .on('propertychange onPropertyChange DOMAttrModified', function(event) {
                                    if (event.originalEvent.attrName === "disabled") {
                                        $(this).attr("fieldset-disabled", false).off('propertychange onPropertyChange DOMAttrModified');
                                    }
                                });
                        } else {
                            element.find('input, select, textarea, button').filter('[fieldset-disabled]').prop('disabled', false).attr("fieldset-disabled", false);
                        }

                    });


                }
            }
        };
    }]);
})();
