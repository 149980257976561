angular.module('wbPublic', [
    'ui.router',
    'ui.router.state.events',
    'ui.mask',
    'ui.validate',
    'ui.bootstrap',
    'ui.bootstrap-slider',
    'ngAnimate',
    'ngMessages',
    'angularMoment',
    'angulartics',
    'angulartics.google.tagmanager',
    'angulartics.mixpanel',
    'angular-bind-html-compile',
    'visibilityChange',
    'ngTable',
    'angular-jwt',
    //custom
    'wbUtilities',
]);
