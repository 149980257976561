(function() {
'use strict';
angular.module('wbPublic').controller('CalculatorController',
    ['$scope', '$q', '$uibModal', 'Misc', 'Request', 'InviteService', '$window', '$location', '$timeout', '$httpParamSerializer', 'Session', 'Milestone',
function($scope, $q, $uibModal, Misc, Request, InviteService, $window, $location, $timeout, $httpParamSerializer, Session, Milestone) {

    $scope.forms = {};
    $scope.pageData = {};
    $scope.pageData.graphValues = [];
    $scope.pageData.confetti = {
        options: {
            target: 'confetti-holder', // Id of the canvas
            max: 200,
            size: 2, // prop size
            animate: true, // Should aniamte?
            props: ['circle', 'square', 'triangle'], // Types of confetti
            //purple, blue, yellow, orange, red
            colors: [[131, 106, 148], [75, 169, 214], [255, 206, 85], [254, 183, 109], [255, 105, 102]], // Colors to render confetti
            clock: 50, // Speed of confetti fall
            onResize: function(options) {
                var container = angular.element('.calculator-container');
                options.height = container.outerHeight() + 100;
                options.width = container.outerWidth();
            }
        }
    };
    $scope.Math = Math;

    $scope.options = {
        incomeLevels: Misc.income_levels,
        milestoneNames: {
            rainy_day: "Rainy Day",
            retirement: "Retirement",
            custom: "Custom Milestone",
        },
        risk: {
            '2.5': "Low Risk",
            '5.0': "Medium Risk",
            '7.5': "High Risk",
        },
        duration: {
            "12" : "One Year",
            "18" : "One Year, 6 Months",
            "24" : "Two Years",
            "30" : "Two Years, 6 Month",
            "36" : "Three Years",
        }
    };

    $scope.signupData = {
        user: {
            auto_deposit: {
                frequency: "monthly"
            }
        },
        detail: {},
        milestone: {
            type: null,
            extra: {},
            metadata: {},
            auto_deposit: {
                amount: 500,
            }
        }
    };

    $scope.stopConfetti = function(slow) {
        $timeout.cancel($scope.stopConfetti.timer);

        if (!slow) {
            $scope.pageData.confetti.stop();
            $scope.pageData.confetti.options.max = 200;
        } else {
            if ($scope.pageData.confetti.options.max > 1) {
                $scope.pageData.confetti.options.max--;
                $scope.stopConfetti.timer = $timeout(function() {
                    $scope.stopConfetti(true);
                }, 12.5);
            } else {
                $scope.stopConfetti();
            }
        }
    };
    // $scope.pageData.confetti.start();

    $scope.isDesktop = function() {
        return $window.innerWidth >= 768;
    };

    $scope.graphHeight = function() {
        if ($scope.isDesktop()) {
            return Math.max($window.innerHeight - 400, 250);
        } else {
            return $window.innerHeight * 0.33;
        }
    };

    $scope.graphMargin = function() {
        if ($scope.isDesktop()) {
            return {top: 20, right: 40, bottom: 50, left: 40};
        } else {
            return {top: 20, right: 0, bottom: 50, left: 0};
        }
    };

    $scope.calculatorDisclaimer = function() {
        var modalInstance = $uibModal.open({
            templateUrl: "calculator-disclaimer-modal.tpl.html",
            size: 'md',
            scope: $scope,
            windowClass: ''
        });
        modalInstance.result.then(function () {
        }).catch(angular.noop);

    };

    $scope.init = function() {

        if ($scope.$stateParams.page !== 'initial') {
            $scope.$stateParams.milestone = null;
            $scope.$state.go(".", {page: 'initial', milestone: null}, {location: 'replace'});
        }

        if ($scope.$stateParams.milestone === 'retirement') {
            $scope.pageData.skipMilestoneSelect = true;
            $scope.signupData.milestone.type = 'retirement';
        }

        let params = $location.search() || {};
        if (params.age) {
            $scope.signupData.detail.age = params.age * 1;
        }
        if (params.annual_income) {
            $scope.signupData.detail.annual_income = params.annual_income;
        }
        if (params.net_worth_id) {
            $scope.signupData.detail.net_worth_id = params.net_worth_id * 1;
        }

        $timeout(function() {
            if ($scope.signupData.detail.age && $scope.signupData.detail.annual_income && $scope.signupData.detail.net_worth_id) {
                if ($scope.signupData.milestone.type) {
                    $scope.goNextPage('preferences');
                } else {
                    $scope.goNextPage('milestone');
                }
            }
        }, 1000);

    };
    $scope.init();

    $scope.getNetWorth = function() {
        var nw = $scope.options.incomeLevels.find((s)=>s.value===$scope.signupData.detail.net_worth_id);
        if (nw) {
            return nw.bin;
        }
        return null;
    };

    $scope.$watch('signupData.email_address', function(email) {
        $scope.pageData.errorOption = null;
        $scope.pageData.errorMessage = null;
    });

    $scope.$watch('signupData.milestone.extra.period', function(months) {
        if (months) {
            $scope.signupData.milestone.extra.period_months = months * 1; //for ms controller
            $scope.signupData.milestone.extra.period_years = 0; //for ms controller

            $scope.signupData.milestone.metadata.months_to_goal = months * 1;
            $scope.signupData.milestone.metadata.target_date = moment().add($scope.signupData.milestone.metadata.months_to_goal, "months").format('YYYY-MM-DD');
        }
    });

    $scope.$watch('signupData.milestone.type', function(type) {
        if (type) {
            if ($scope.$stateParams.page === 'milestone') {
                updateMilestoneUrl();
            }
        }
    });
    function updateMilestoneUrl() {
        $location.search('milestone', $scope.signupData.milestone.type).replace();
    }

    $scope.$watch('signupData.detail.annual_income', function(income) {
        if (income) {
            $scope.signupData.milestone.auto_deposit.amount = Math.round(income / 12 * 0.09);
        }
    });

    $scope.$watch('signupData.milestone.type', function(type) {
        if (type) {
            $scope.pageData.estimateGraph = {
                plotData: []
            };
        }
    });

    $scope.$watchGroup(['$stateParams.page', 'signupData.user.risk_score_chosen','signupData.milestone.auto_deposit.amount',
                'signupData.milestone.extra.period', 'signupData.milestone.metadata.retirement_age', 'signupData.milestone.target_amount'], function(data) {
        var [page, risk, autoDeposit, months, retireAge, target] = data;
        if (page === 'preferences' && risk && autoDeposit && (months || retireAge || target)) {
            $scope.updateData();
        }

    });

    //This will clear the settings for the milestones that aren't selected
    $scope.resetMilestoneData = function() {
        if ($scope.signupData.milestone.type) {

            delete $scope.signupData.milestone.extra.target_amount;
            delete $scope.signupData.milestone.metadata.spend_per_year;
            delete $scope.signupData.milestone.extra.target_month;
            delete $scope.signupData.milestone.extra.target_year;

            if (!$scope.signupData.user.risk_score_chosen) {
                $scope.signupData.user.risk_score_chosen = "5.0"; //medium
            }

            //set what it is
            if ($scope.signupData.milestone.type === "rainy_day") {
                if (!$scope.signupData.milestone.extra.period) {
                    $scope.signupData.milestone.extra.period = "36";
                }
                //metadata will get set in watcher
            }
            if ($scope.signupData.milestone.type === "retirement") {
                if (!$scope.signupData.milestone.metadata.retirement_age) {
                    $scope.signupData.milestone.metadata.retirement_age = 70;
                }
            }
            if ($scope.signupData.milestone.type === "custom") {
                if (!$scope.signupData.milestone.target_amount) {
                    if ($scope.signupData.detail.annual_income > 50000) {
                        $scope.signupData.milestone.target_amount = 10000;
                    } else {
                        $scope.signupData.milestone.target_amount = 2000;
                    }
                }
            }

            //clear what it's not
            if ($scope.signupData.milestone.type !== "rainy_day") {
                delete $scope.signupData.milestone.extra.period;
                delete $scope.signupData.milestone.metadata.months_to_goal;
                delete $scope.signupData.milestone.metadata.target_date;
            }
            if ($scope.signupData.milestone.type !== "retirement") {
                delete $scope.signupData.milestone.metadata.retirement_age;
            }
            if ($scope.signupData.milestone.type !== "custom") {
                delete $scope.signupData.milestone.target_amount;
            }
        }
    };

    $scope.updateData = function() {
        // if ($scope.skipUpdate) {
        //     $scope.skipUpdate = false;
        //     return;
        // }

        let d = $scope.signupData;

        let data = {
            msType: d.milestone.type,
            age: d.detail.age,
            net_worth_id: d.detail.net_worth_id,
            annual_income: d.detail.annual_income,
            auto_deposit_amount: d.milestone.auto_deposit.amount.toFixed(2),
            months_to_goal: d.milestone.metadata.months_to_goal, //rainy_day
            retirement_age: d.milestone.metadata.retirement_age, //retirement
            target_amount: d.milestone.target_amount, //custom
        };

        switch (d.user.risk_score_chosen) {
            case "2.5": data.risk_tolerance = 'LOW'; break;
            case "5.0": data.risk_tolerance = 'MEDIUM'; break;
            case "7.5": data.risk_tolerance = 'HIGH'; break;
        }


        $scope.pageData.sending = true;
        $scope.pageData.errorMessage = null;

        let valuesFlat = $scope.pageData.graphValues.map(v=>{ return {x:v.x, y:0}; });
        $scope.pageData.estimateGraph = {
            plotData: [{values: valuesFlat}]
        };

        return Request.make('public_calculator', data).then(function (response) {
            var r = response.data;

            $scope.forms.calculator.auto_deposit.$setValidity("attainable", true);

            let values = [];
            r.totals.forEach(function(value) {
                value.date = value.date + "-01";
                value.value = value.value * 1;

                //set graph x & y values
                value.x = moment(value.date).valueOf(); //new Date improperly sets UTC, use moment
                value.y = value.value;

                values.push(value);
            });

            let [lastItem] = values.slice(-1);
            let lastDate = moment(lastItem.date);

            $scope.signupData.milestone.extra.target_date = lastDate.format('YYYY-MM-DD');
            $scope.signupData.milestone.extra.target_month = lastDate.month() + 1;
            $scope.signupData.milestone.extra.target_year = lastDate.year();

            $scope.signupData.milestone.extra.spend_per_year = Math.round(r.spend_per_year);
            $scope.signupData.milestone.extra.target_amount = Math.round(lastItem.value); //on extra to prevent triggering update watcher

            $scope.pageData.estimateGraph = {
                plotData: [{values: values}]
            };
            $scope.pageData.graphValues = values;

            return r;
        }).catch(function(response) {
            if (response.status === 400) {
                if (response.data && response.data.details) {
                    $scope.pageData.errorMessage = response.data.details[0].message;

                    if (response.data.details[0].code === "Conflict") {
                        $scope.signupData.milestone.extra.target_date = null;
                        $scope.signupData.milestone.extra.target_month = null;
                        $scope.signupData.milestone.extra.target_year = null;
                        $scope.forms.calculator.auto_deposit.$setValidity("attainable", false);
                    }
                }
            }
        }).finally(function() {
            $scope.pageData.sending = false;
        });
    };

    $scope.submit = function(reset = false) {
        $scope.pageData.sending = true;
        $scope.pageData.errorOption = null;
        $scope.pageData.errorMessage = null;

        let code;
        let haltSubmit = false;

        let options = {};
        if (reset) {
            options.reset = reset;
        }

        InviteService.submitSignup($scope.signupData.email_address, options).catch(function(response) {
            $scope.pageData.sending = false;

            $scope.stopConfetti(true); //lol

            if (response.status === 418) {
                $window.location.href = `/dashboard/login`;
            } else if (response.status === 428) {
                //display to the user to either check email or reset data
                //response.data.code
                $scope.pageData.errorOption = "otp_email";
            } else {
                $scope.pageData.errorMessage = "Either the email address was incorrect or there already exists an account with that email address";
                if (response.status) {
                    if (response.data && response.data.error && response.data.error.message) {
                        $scope.pageData.errorMessage = response.data.error.message;
                    } else if (response.data && response.data.data && response.data.data.message) {
                        //error format used in maintenance mode
                        $scope.pageData.errorMessage = response.data.data.message;
                    }
                }
            }
            // angular.element("html, body").animate({scrollTop: 0}, 200);

            haltSubmit = true;
            throw response;
        }).then(function(response) {
            $timeout(function() {
                $scope.pageData.confetti.start();
            });

            code = response.data.code;

            return $scope.updateProgress(code);

        }).then(function(progress) {

        }).catch(function() {
            //ignore, just need to catch thrown error from first catch
        }).finally(function() {
            if (!haltSubmit) {
                //no error handling for forms, so always want to get to here at the end
                // $window.location.href = `/signup/${code}`;
                $window.location.href = InviteService.getAxosEnrollLink();
            }
        });
    };

    $scope.updateProgress = function(code) {
        //have the code submit to progress
        //redirect to signup
        let saveData = angular.copy($scope.signupData);

        saveData.code = code;

        saveData.milestones = [saveData.milestone];

        switch (saveData.milestone.type) {
            case 'rainy_day':
                saveData.milestone.target_amount = saveData.milestone.extra.target_amount;
                break;
            case 'retirement':
                saveData.milestone.metadata.spend_per_year = saveData.milestone.extra.spend_per_year;
                if (saveData.milestone.metadata.spend_per_year < 20000) {
                    saveData.milestone.metadata.spend_per_year = 20000;
                }
                break;
            case 'custom':
                saveData.milestone.metadata.target_date = saveData.milestone.extra.target_date;
                break;
        }

        delete saveData.milestone.extra;
        delete saveData.milestone;
        delete saveData.detail.age;

        return Request.make('signup_update', saveData).catch(function() {
            //ignore fail here, just continue to signup
        });

    };

    /**
     * Submit from embeded page to full calculator
     */
    $scope.homePageSubmit = function() {
        let params = $httpParamSerializer($scope.signupData.detail);
        $window.location.href = "/managed-portfolios/investment-calculator?" + params;
    };


    /*********************
     * PAGE MANAGEMENT
     *********************/

    $scope.pages = ['initial', 'milestone', 'preferences', 'congratulations'];

    $scope.goNextPage = function(nextPage) {
        var currentPage = $scope.$stateParams.page;
        var pageIndex = $scope.pages.indexOf(currentPage);

        if (currentPage === 'congratulations') {
            $scope.submit();
            return;
        }

        switch (currentPage) {
            case 'initial':
                if ($scope.pageData.skipMilestoneSelect) {
                    nextPage = 'preferences';
                }
            break;
        }

        if (!nextPage) {
            nextPage = $scope.pages[pageIndex + 1];
        }

        nextPage = $scope.beforePage(nextPage);

        if (nextPage) {
            $scope.goToPage(nextPage).then(function(trans) {
                $scope.afterPageLoad(nextPage);
            });
        }

    };

    $scope.beforePage = function(page) {
        var nextPage = page;
        var pageIndex = $scope.pages.indexOf(page);

        switch (nextPage) {
            case 'preferences':
                $scope.resetMilestoneData();
                // $scope.updateData().then(function() {
                //     $scope.goToPage('preferences');
                // });
                // nextPage = null;
                break;
            default:
                break;
        }
        if (nextPage !== page) {
            nextPage = $scope.beforePage(nextPage);
        }
        return nextPage;
    };

    $scope.afterPageLoad = function(page) {
        //after page load
        switch (page) {
            case 'milestone':
                if (!$scope.signupData.milestone.type) {
                    $scope.signupData.milestone.type = 'rainy_day';
                } else {
                    updateMilestoneUrl();
                }
                break;
            case 'congratulations':
                $timeout(function() {
                    //need to give it time for the directive to load
                    $scope.pageData.confetti.start();
                });
                break;
        }

    };

    $scope.goToPage = function(page, params = {}) {
        if (page) {
            params.page = page;
            return $scope.$state.go(".", params);
        }
    };


    var transitionDeregisterHook;
    transitionDeregisterHook = $scope.$transitions.onBefore({ to: 'calculator' }, function(trans) {
        $scope.pageData.errorMessage = null;

        if (trans.params('to').page === "preferences" && trans.params('from').page !== "congratulations") {
            // $scope.updateData();
        }
    });
    $scope.$on('$destroy', function() {
        transitionDeregisterHook();
    });


}]);
})();
