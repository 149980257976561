(function() {
    'use strict';

    angular.module('wbPublic').controller('EmailPreferencesController', ['$scope', 'Request', '$window', function ($scope, Request, $window) {
        $scope.pageData = {
            sending: false,
            success: false,
            errorMsg: null,
        };
        $scope.forms = {};
        $scope.unsubscribe = {};

        $scope.unsubscribeSubmit = function () {
            $scope.pageData.sending = true;
            $scope.pageData.errorMsg = null;
            Request.make("user_preferences_email", {"email": $scope.unsubscribe.email, "categories": {"announcements": false}}).then(function () {
                $scope.pageData.success = true;
            }).catch(function (response) {
                var message = "There was an issue unsubscribing you, please try again later";
                try {
                    message = response.data.error.messsage;
                } catch (err) {}
                $scope.pageData.errorMsg = message;
            }).finally(function() {
                $scope.pageData.sending = false;
            });
        };

    }]);
})();
