(function() {
    'use strict';
    angular.module('wbPublic').directive('publicHeader',
        ['$state', '$timeout', '$window', '$location', '$transitions', 'InviteService',
    function ($state, $timeout, $window, $location, $transitions, InviteService) {
        return {
            restrict: 'A',
            scope: true,
            templateUrl: 'app/directives/header/header.tpl.html',
            link: function (scope, element) {

                angular.element('header .space-holder .navbar-brand').addClass('invisible');

                scope.getAxosEnrollLink = InviteService.getAxosEnrollLink;
                scope.axosDomain = $window.consts.axosDomain;

                scope.indexPages = ["root", "/r/", "/a/", "/referral/"];

                scope.navData = {
                    collapse: true,
                    dropDowns: {},
                    showHeader: true,
                    nearTop: isPage(scope.indexPages),
                };

                var lastScrollTop = 0;
                angular.element($window).scroll(function(event){
                    var scroll = $(this).scrollTop();
                    var prevHeader = scope.navData.showHeader;
                    var prevTop = scope.navData.nearTop;
                    if (scroll > lastScrollTop && scroll > 200) {
                        //scroll down
                        if (scope.navData.collapse === true) {
                            //don't hide if the menu is open
                            scope.navData.showHeader = false;
                        }
                    } else {
                        //scroll up
                        scope.navData.showHeader = true;
                    }
                    scope.navData.nearTop = scroll < 370 && isPage(scope.indexPages);
                    if (prevHeader !== scope.navData.showHeader || prevTop !== scope.navData.nearTop) {
                        scope.$apply();
                    }
                    lastScrollTop = scroll;
                });

                scope.$watch(function() { return InviteService.referral; }, function(referral) {
                    scope.referral = referral;
                });

                scope.isPage = isPage;

                $timeout(function() {
                    angular.element($window).trigger('scroll');
                });

                function isPage(page, tail = false) {
                    var found = false;
                    if (typeof page !== "object") {
                        page = [page];
                    }
                    var path = $location.path();

                    if (tail) {
                        path = path.split('/').filter(n=>n);
                        path = path.pop() || "";
                    }

                    page.forEach(function(p) {
                        if (p === "root") {
                            found = found || path === '/';
                        } else {
                            found = found || path.includes(p);
                        }
                    });
                    return found;
                }

                scope.getHomeLink = function() {
                    let home = "/";

                    ['/r/', '/referral/', '/a/', '/invited/', '/unsubscribe', '/reminders'].forEach(function(i) {
                        if ($location.path().includes(i)) {
                            home = "managed-portfolios";
                        }
                    });

                    return home;
                };

                scope.isDesktop = function() {
                    return $window.innerWidth >= 768;
                };

                scope.collapseNav = function() {
                    //prevent odd state where nav is collapsed but screen is resized
                    // and then overflow on body is still hidden
                    scope.navData.collapse = true;
                    $timeout(function() { scope.$apply(); });
                };
                angular.element($window).on('resize', scope.collapseNav);

                var transitionDeregisterHook = $transitions.onBefore({}, function(trans) {
                    scope.navData.collapse = true;
                });
                scope.$on('$destroy', function() {
                    if (transitionDeregisterHook) {
                        transitionDeregisterHook();
                    }
                    angular.element($window).off('resize', scope.collapseNav);
                });


            }
        };
    }]);
})();
