angular.module('wbPublic').config([
    '$stateProvider', '$provide', '$urlServiceProvider', '$locationProvider', '$analyticsProvider', '$windowProvider',
    'CommonConstant', 'bugsnagClientProvider',
function ($stateProvider, $provide, $urlServiceProvider, $locationProvider, $analyticsProvider, $windowProvider,
          CommonConstant, bugsnagClientProvider) {

    $locationProvider.html5Mode(true).hashPrefix('!');
    $urlServiceProvider.config.strictMode(false);

    bugsnagClientProvider.init({
        releaseStage: CommonConstant.environment,
        apiKey: CommonConstant.bugsnagApiKey
    });

    $stateProvider
        .state('page', {
            //doesn't do anything, controller added via ng-controller on page
            url: "/",
            controller: "PageController",
            //template bug: https://github.com/angular-fullstack/generator-angular-fullstack/issues/2226
            template: " ",
            resolve: {
                // test: ['$state', '$q', '$timeout', '$transition$', function ($state, $q, $timeout, $transition$) {
                //     return $q.when(1).then(function () {
                //     });
                // }]
            }
        }).state('invited', {
            //doesn't do anything, controller added via ng-controller on page
            url: "/invited/{code}?token",
            controller: "InvitedPageController",
            template: " ",
        })
        .state('careers', {
            url: "/careers",
            controller: ['$window', function($window) {
                $window.location.href = "https://careers-axossecurities.icims.com";
            }]
        })
        .state('calculator', {
            url: "/managed-portfolios/investment-calculator/{page}?milestone",
            controller: "CalculatorController",
            templateUrl: "app/segments/calculator/calculator.tpl.html",
            params: {
                page: { value: "initial", squash: true, dynamic: true },
                milestone: { value: null, dynamic: true },
            }
        });

    /*- Configure Angulartics to use state base -*/
    $analyticsProvider.withBase(true);


    /** Log bugs with bugsnag **/
    $provide.decorator("$exceptionHandler", ['$delegate', '$injector', function($delegate, $injector) {
        return function(exception, cause) {

            let $state = $injector.get('$state');

            bugsnagClientProvider.handleBugsnagException(exception, cause, $state);

            $delegate(exception, cause);

        };
    }]);

}]);
